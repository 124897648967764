import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { COLORS } from "../../constants";
import InventoryTable from "../includes/Inventory/InventoryTable";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import useGetApi from "../../hooks/useGetApi";

function Inventory() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("inventory"));
	}, []);
	//
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const filterData = [
		{ id: 1, name: "All Stock" },
		{ id: 2, name: "Out of Stocks" },
		{ id: 3, name: "Low Stocks" },
	];
	const [activeFilter, setActiveFiter] = useState({
		id: 1,
		name: "All Stock",
	});
	const {
		data: OrderData,
		refetch,
		loading,
	} = useGetApi("/shops/admin/inventory/", {
		page: page,
	});
	const [inventary, setInventary] = useState(OrderData);
	useEffect(() => {
		if (OrderData) {
			setInventary(OrderData);
		}
	}, [OrderData]);
	useEffect(() => {
		if (inventary?.count) {
			setPageCount(Math.ceil(inventary.count / 10));
		}
	}, [inventary]);
	console.log(OrderData, "gg");

	//
	const filteredData = useMemo(() => {
		if (activeFilter.name === "All Stock") {
			return OrderData?.results || [];
		}
		if (activeFilter.name === "Out of Stocks") {
			return (OrderData?.results || []).filter(
				(inventary) => inventary?.stock_status?.status === "out_of_stock"
			);
		}
		if (activeFilter.name === "Low Stocks") {
			return (OrderData?.results || []).filter(
				(inventary) => inventary?.stock_status?.status === "low_stock"
			);
		}
	});
	//

	return (
		<Container>
			<Title>Inventory</Title>
			<FilterMenu>
				{filterData?.map((item) => (
					<Menu
						key={item.id}
						className={activeFilter.id === item.id ? "active" : ""}
						onClick={() => setActiveFiter(item)}
					>
						{item.name}
					</Menu>
				))}
			</FilterMenu>
			<InventoryTable
				data={filteredData}
				fetchData={refetch}
				loading={loading}
				pageCount={pageCount}
				totalPages={pageCount}
				page={page}
				setPage={setPage}
				currentPage={page}
				OrderData={OrderData}
			/>
		</Container>
	);
}

export default Inventory;
const Container = styled.div`
	position: relative;
`;
const Title = styled.h2`
	font-size: 18px;
	margin-bottom: 10px;
`;
const FilterMenu = styled.div`
	background-color: ${COLORS.PRIMARY};
	padding: 10px 16px;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	margin-bottom: 10px;
	position: -webkit-sticky; /* Safari */
`;
const Menu = styled.span`
	padding: 6px 16px;
	display: block;
	border-radius: 3px;
	transition: 0.3s;
	font-size: 14px;
	text-transform: capitalize;
	color: ${COLORS.SECONDARY_TEXT};
	cursor: pointer;
	&.active {
		background: ${COLORS.THEME_GRADIENT};
		color: ${COLORS.PRIMARY_TEXT};
	}
	&:hover {
		background: ${COLORS.THEME_GRADIENT};
		color: ${COLORS.PRIMARY_TEXT};
	}
`;

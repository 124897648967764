export const API_BASE_URL = "https://api.oneruppee.com/api/v1/";
// export const API_BASE_URL = "http://192.168.204.246:8000/api/v1/";

export const DEFAULT_PAGE_LIMIT = 20;
export const MAX_UPLOAD_SIZE_MB = 10;

export const ERROR_MESSAGES = {
    SERVER_ERROR: "An error occurred while communicating with the server.",
    NETWORK_ERROR:
        "An error occurred while attempting to connect to the server. Please check your internet connection and try again.",
    INVALID_INPUTS:
        "One or more inputs are invalid. Please check the form and try again.",
};

export const USER_ROLES = {
    ADMIN: "admin",
    MODERATOR: "moderator",
    GUEST: "guest",
};

export const COLORS = {
    PRIMARY: "#FFF",
    SECONDARY: "#F5F5F5;",
    THEME_COLOR: "#FFF2A1",
    BACKGROUND: "#F8F8F8",
    PRIMARY_TEXT: "#0A0A0A",
    SECONDARY_TEXT: "#747474",
    THEME_COLOR: "#EAB600",
    THEME_GRADIENT: "linear-gradient(340.8deg, #FFDF17 18.2%, #FFF79E 167.34%)",
    SUCESS: "#28a745",
    INFO: "#17a2b8",
    WARNING: "#ffc107",
    DANGER: "#dc3545",
    DARK: "#343a40",
    LIGHT: "#f8f9fa",
    black: "#000",
};

export const PLACEHOLDERS = {
    USERNAME: "Enter your username",
    EMAIL: "Enter your email address",
    PASSWORD: "Enter your password",
};

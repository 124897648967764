import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axiosInstance from "../../../api";

function ShopDetails({ shop_data }) {
	const [activeInputs, setActiveInputs] = useState({
		title: false,
		address_line1: false,
		address_line2: false,
		city: false,
		state: false,
		pincode: false,
	});
	const schema = Yup.object({
		title: Yup.string().required("Shop Name is Required"),
		address_line1: Yup.string().required("Adress Line 1 is Required"),
		address_line2: Yup.string().required("Address Line 2 is Required"),
		city: Yup.string().required("City is Required"),
		state: Yup.string().required("State is Required"),
		pincode: Yup.number()
			.positive("Pincode nust be a positive number")
			.required("Pin Code is Required"),
	});
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		if (shop_data) {
			setValue("title", shop_data.title);
			setValue("address_line1", shop_data.address_line1);
			setValue("address_line2", shop_data.address_line2);
			setValue("city", shop_data.city);
			setValue("state", shop_data.state);
			setValue("pincode", shop_data.pincode);
		}
	}, [shop_data, setValue]);
	const onSubmit = (data) => {
		axiosInstance
			.patch(`shops/${shop_data.id}/`, data)
			.then((res) => {
				const { title, address_line1, address_line2, city, state, pincode } =
					res.data;
				setValue("title", title);
				setValue("address_line1", address_line1);
				setValue("address_line2", address_line2);
				setValue("city", city);
				setValue("state", state);
				setValue("pincode", pincode);
				alert("Shop details updated successfully!");
			})
			.catch((err) => {
				alert("Failed to update shop!");
			});
	};
	const handleFocus = (field) => {
		setActiveInputs({
			title: false,
			address_line1: false,
			address_line2: false,
			city: false,
			state: false,
			pincode: false,
			[field]: true,
		});
	};

	const handleBlur = (field) => {
		setActiveInputs((prev) => ({
			...prev,
			[field]: false,
		}));
	};
	return (
		<Container>
			<Title>Shop Details</Title>
			<FormSection onSubmit={handleSubmit(onSubmit)}>
				<InputWrapper>
					<InputLabel>Shop Name</InputLabel>
					<TextInput
						className={activeInputs.title ? "active" : ""}
						onFocus={() => handleFocus("title")}
						onBlur={() => handleBlur("title")}
						{...register("title")}
						type="text"
						name="title"
						placeholder="Enter Shop Name"
					/>
					<Error>{errors.name?.message}</Error>
				</InputWrapper>
				<SectionBreaker>
					<SectionTitle>Shop&#160;Address</SectionTitle>
					<Line>
						<span></span>
					</Line>
				</SectionBreaker>
				<InputWrapper>
					<InputLabel>Address Line 1</InputLabel>
					<TextInput
						className={activeInputs.address_line1 ? "active" : ""}
						onFocus={() => handleFocus("address_line1")}
						onBlur={() => handleBlur("address_line1")}
						{...register("address_line1")}
						type="text"
						name="address_line1"
						placeholder="Enter Address Line 1"
					/>
					<Error>{errors.address_line1?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>Address Line 2</InputLabel>
					<TextInput
						className={activeInputs.address_line2 ? "active" : ""}
						onFocus={() => handleFocus("address_line2")}
						onBlur={() => handleBlur("address_line2")}
						{...register("address_line2")}
						type="text"
						name="address_line2"
						placeholder="Enter Address Line 2"
					/>
					<Error>{errors.address_line2?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>City</InputLabel>
					<TextInput
						className={activeInputs.city ? "active" : ""}
						onFocus={() => handleFocus("city")}
						onBlur={() => handleBlur("city")}
						{...register("city")}
						type="text"
						name="city"
						placeholder="Enter City"
					/>
					<Error>{errors.city?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>State</InputLabel>
					<TextInput
						className={activeInputs.state ? "active" : ""}
						onFocus={() => handleFocus("state")}
						onBlur={() => handleBlur("state")}
						{...register("state")}
						type="text"
						name="state"
						placeholder="Enter State"
					/>
					<Error>{errors.state?.message}</Error>
				</InputWrapper>
				<InputWrapper>
					<InputLabel>Pincode</InputLabel>
					<TextInput
						className={activeInputs.pincode ? "active" : ""}
						onFocus={() => handleFocus("pincode")}
						onBlur={() => handleBlur("pincode")}
						{...register("pincode")}
						type="numeric"
						name="pincode"
						placeholder="Enter Pincode"
					/>
					<Error>{errors.pincode?.message}</Error>
				</InputWrapper>{" "}
				<ButtonContainer>
					<CancelButton>Cancel</CancelButton>
					<SubmitButton type="submit">Save</SubmitButton>
				</ButtonContainer>
			</FormSection>
		</Container>
	);
}
export default ShopDetails;
const Container = styled.div`
	background-color: #fff;
	padding: 20px;
	position: relative;
`;
const Title = styled.h3`
	font-size: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid ${COLORS.SECONDARY};
	font-family: dm_sans_reg;
`;
const FormSection = styled.form`
	display: block;
	margin-top: 30px;
`;
const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	position: relative;
`;

const InputLabel = styled.label`
	font-size: 16px;

	color: ${COLORS.SECONDARY_TEXT};
`;
const TextInput = styled.input`
	padding: 12px 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 60%;
	border: 1px solid #eeeeee;
	&.active {
		border: ${COLORS.THEME_COLOR} solid 1px;
	}
`;
const Error = styled.p`
	font-size: 12px;
	color: red;
	bottom: -20px;
	right: 0;
	position: absolute;
`;
const SectionBreaker = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 30px;
`;
const SectionTitle = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 16px;
	margin-right: 20px;
`;
const Line = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	span {
		display: block;
		flex: 1;
		border-bottom: 1px solid #f0f0f0;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
`;
const CancelButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	border-radius: 5px;
`;

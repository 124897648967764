import React, { useEffect, useState } from "react";
import styled from "styled-components";
import action from "../../../assets/images/more.svg";
import { COLORS } from "../../../constants";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import OutsideClick from "../../general/OutSideClick.jsx";
import Tick from "../../../assets/images/tick.png";
import Download from "../../../assets/images/Download.png";
import Delivery from "../../../assets/images/Delivery.png";
import Stock from "../../../assets/images/Stock.png";
import More from "../../../assets/images/More.png";

import Cancel_Btn from "../../../assets/images/cancel_btn.png";

const OrderActionsButton = ({
	handleCancelModal,
	handleConfirmModal,
	data,
	selectedOrder,
	setSelectedOrder,
	handleAddStockModal,
	getStockCount,
}) => {
	const [showOptions, setShowOptions] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalData, setModalData] = useState(null);

	const handleCancelOrderClick = () => {
		setSelectedOrder(null);
		handleCancelModal();
	};

	const handleAddStock = () => {
		handleAddStockModal(); // Trigger the Add Stock Modal
	};

	const clearSelectedOrder = () => {
		setSelectedOrder("");
		setModalVisible(false);
	};

	useEffect(() => {
		if (selectedOrder && Array.isArray(data)) {
			const selectedOrderData = data.find((item) => item.id === selectedOrder);
			setModalData(selectedOrderData);
		} else {
			setModalData(null);
		}
	}, [selectedOrder, data]);

	return (
		<Container>
			{data.status === "cancelled" ? (
				""
			) : (
				<>
					<BtnContainer className={modalVisible ? "active" : ""}>
						<Button
							onClick={() => {
								setSelectedOrder(data.id);
								setModalVisible(true);
							}}
						>
							{modalVisible ? (
								<img src={More} alt="More options" />
							) : (
								<img src={action} alt="More options" />
							)}
						</Button>
					</BtnContainer>
				</>
			)}
			{modalVisible && (
				<Modal>
					<OutsideClick onClickOutside={clearSelectedOrder}>
						<ModalContent
							className={
								["low_stock", "out_of_stock", "in_stock"].includes(
									data.stock_status.status
								)
									? "low_Stock"
									: ""
							}
						>
							{data.status === "ordered" && (
								<div className="ordered_btn">
									<button className="confirm">
										<img src={Tick} alt="tick" />
										Confirm Order
									</button>
									<button className="cancel">
										<img src={Cancel_Btn} alt="cancel" />
										Cancel Order
									</button>
								</div>
							)}
							{(data.status === "shipped" || data.status === "packed") && (
								<div className="ordered_btn">
									<button className="download">
										<img className="imgs" src={Download} alt="download" />
										Download Slip
									</button>
									<button className="cancel" onClick={handleCancelOrderClick}>
										<img className="imgs" src={Cancel_Btn} alt="cancel" />
										Cancel Order
									</button>
								</div>
							)}
							{data.status === "confirm" && (
								<div className="ordered_btn">
									<button className="ready">
										<img className="imgs" src={Delivery} alt="delivery" />
										Ready to Ship
									</button>
									<button className="download">
										<img className="imgs" src={Download} alt="download" />
										Download Slip
									</button>
									<button className="cancel">
										<img className="imgs" src={Cancel_Btn} alt="cancel" />
										Cancel Order
									</button>
								</div>
							)}
							{(data.stock_status.status === "low_stock" ||
								data.stock_status.status === "out_of_stock" ||
								data.stock_status.status === "in_stock") && (
								<div className="stock_btn">
									<button
										className="stock"
										onClick={() => {
											handleAddStock();
											getStockCount(data.id);
										}}
									>
										<img className="imgs" src={Stock} alt="stock" />
										Add Stock
									</button>
								</div>
							)}
						</ModalContent>
					</OutsideClick>
				</Modal>
			)}
		</Container>
	);
};

export default OrderActionsButton;

const Container = styled.div`
	position: relative;
`;

const BtnContainer = styled.div`
	width: 40px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	border-radius: 5px;
	&.active {
		background-color: #fff2a1;
	}
`;

const Button = styled.span`
	display: block;
	width: 30px;
	transition: 0.1s;
	position: relative;
	img {
		display: block;
		width: 100%;
	}
	:hover {
		opacity: 0.5;
	}
`;

const Modal = styled.div`
	position: absolute;
	top: 30px;
	right: 0px;
	background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(255, 255, 255);
	padding: 10px 20px;
	border-radius: 5px;
	width: 168px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	height: 95px;
	&.low_Stock {
		height: 50px;
		justify-content: left;
	}
	& div.ordered_btn {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		& button.confirm {
			background: transparent;
			border: none !important;
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 700;
			color: #0a0a0a;
			border-bottom: 1px solid #d4d4d4 !important;
			padding: 10px 0 !important;
			cursor: pointer;
		}
		& button.cancel {
			background: transparent;
			border: none !important;
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 700;
			padding: 10px 0 !important;
			cursor: pointer;
			color: #e01507;
		}
		& button.download {
			background: transparent;
			border: none !important;
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 700;
			color: #0a0a0a;
			border-bottom: 1px solid #d4d4d4 !important;
			padding: 10px 0 !important;
			cursor: pointer;
		}
		& button.ready {
			background: transparent;
			border: none !important;
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 700;
			color: #0a0a0a;
			border-bottom: 1px solid #d4d4d4 !important;
			padding: 10px 0 !important;
			cursor: pointer;
		}
		& img.imgs {
			width: 26px;
		}
	}
	& div.stock_btn {
		& button.stock {
			background: transparent;
			border: none !important;
			display: flex;
			align-items: center;
			gap: 8px;
			font-weight: 700;
			color: #0a0a0a;
			padding: 10px 0 !important;
			cursor: pointer;
		}
	}
`;

import React from "react";
import styled from "styled-components";
import CancelIcon from "../../../assets/images/cancel.svg";
import confirm from "../../../assets/images/order-confirmed.svg";
import shipped from "../../../assets/images/shipped.svg";
import TimeIcon from "../../../assets/images/time.svg";
import ReturnIcon from "../../../assets/images/return.svg";
import DeliveredIcon from "../../../assets/images/order-delivered-admin.svg";

const OrderStatusButton = ({ status }) => {
    let fontColor;
    let bgColor;
    let icon;
    let label;

    if (status === "confirmed") {
        fontColor = "#009262";
        bgColor = "#EAF8F2";
        icon = confirm;
        label = "Confirm";
    } else if (status === "ordered") {
        fontColor = "#FFB240";
        bgColor = "#FFFFF0";
        icon = TimeIcon;
        label = "Not Confirmed";
    } else if (status === "cancelled") {
        fontColor = "#E02B1D";
        bgColor = "#FCEAE8";
        icon = CancelIcon;
        label = "Cancelled Order";
    } else if (["shipped", "packed"].includes(status)) {
        fontColor = "#5261C7";
        bgColor = "#EDEEF4";
        icon = shipped;
        label = "Shipped";
    } else if (["return_dispatched", "return_received"].includes(status)) {
        fontColor = "#E02B1D";
        bgColor = "#FCEAE8";
        icon = ReturnIcon;
        label = "Returned Order";
    } else if (status === "delivered") {
        fontColor = "#009262";
        bgColor = "#EAF8F2";
        icon = DeliveredIcon;
        label = "Delivered";
    } else {
        fontColor = "#2f363d";
        bgColor = "#fff";
        label = "Unknown Status";
    }

    return (
        <Button fontColor={fontColor} bgColor={bgColor}>
            {icon && (
                <Icon>
                    <img src={icon} alt="icon" />
                </Icon>
            )}
            {label}
        </Button>
    );
};

const Button = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    color: ${(props) => props.fontColor};
    background-color: ${(props) => props.bgColor};
    transition: background-color 0.3s;
`;

const Icon = styled.span`
    min-width: 20px;
    width: 20px;
    display: block;
    margin-right: 6px;
    img {
        display: block;
        width: 100%;
    }
`;

export default OrderStatusButton;

import React, { useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, COLORS } from "../../../../constants";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../../../../api";
import { toast } from "react-toastify";

function AddStockModal({ handleModal, isModal, stockData, fetchData }) {
	const hasAttributes = stockData?.has_attribute ?? false;
	const stockResult = stockData?.result ?? [];

	const [quantities, setQuantities] = useState(
		stockResult.length > 0 ? stockResult.map(() => "") : [""]
	);

	const handleQuantityChange = (index, value) => {
		const updatedQuantities = [...quantities];
		updatedQuantities[index] = value;
		setQuantities(updatedQuantities);
	};

	const handleCloseModal = () => {
		setQuantities(stockResult.map(() => ""));
		handleModal();
	};

	const isSubmitEnabled = quantities.some((qty) => qty.trim() !== "");
	return (
		<ModalWrapper isModal={isModal}>
			<ModalContent>
				<ModalTopSection>
					<Title>Add Quantity</Title>
					<CloseButton onClick={handleCloseModal}>
						<CloseIcon />
					</CloseButton>
				</ModalTopSection>

				<InputSection>
					{hasAttributes ? (
						stockResult.map((item, index) => (
							<StockInput key={index}>
								<Label htmlFor={`orderId-${index}`}>
									{item.attribute_value}
									<span style={{ color: "#74747480", fontSize: "14px" }}>
										(Current qty: {item.stock})
									</span>
								</Label>
								<Input
									type="numeric"
									id={`orderId-${index}`}
									placeholder="Enter qty to add"
									value={quantities[index]}
									onChange={(e) => handleQuantityChange(index, e.target.value)}
								/>
							</StockInput>
						))
					) : (
						<StockInput>
							<Label htmlFor="orderId">
								<span style={{ color: "#74747480", fontSize: "14px" }}>
									(Current qty: {stockResult[0]?.stock})
								</span>
							</Label>
							<Input
								type="numeric"
								id="orderId"
								placeholder="Enter qty to add"
								value={quantities[0] || ""}
								onChange={(e) => handleQuantityChange(0, e.target.value)}
							/>
						</StockInput>
					)}
				</InputSection>

				<ButtonContainer>
					<CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
					<ConfirmButton
						className={isSubmitEnabled ? "enabled" : "disabled"}
						disabled={!isSubmitEnabled}
						onClick={async () => {
							try {
								// Create an array of promises for API calls
								const apiCalls = stockResult.map((item, index) => {
									if (quantities[index]) {
										const payload = {
											id: item.id, // Replace with the correct identifier
											stock: parseInt(quantities[index]), // Convert quantity to integer
										};

										return axiosInstance.post(
											`${API_BASE_URL}shops/admin/inventory/update-product-stock/${item.id}/`,
											payload
										);
									}
								});

								// Execute all API calls concurrently
								const responses = await Promise.all(apiCalls);

								toast.success("All Stocks updated successfully.");
								fetchData();
								handleCloseModal();
							} catch (error) {
								console.error("Error updating stocks:", error);
							}
						}}
					>
						Submit
					</ConfirmButton>
				</ButtonContainer>
			</ModalContent>
		</ModalWrapper>
	);
}

export default AddStockModal;

// Styled Components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(3px);
	display: ${(props) => (props.isModal ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	z-index: 1000;
`;

const ModalContent = styled.div`
	background: ${COLORS.white};
	padding: 40px;
	border-radius: 8px;
	width: 600px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1001;
	background-color: #fff;
`;

const ModalTopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	padding-bottom: 30px;
	border-bottom: 2px solid #ebebeb;
`;

const Title = styled.h3`
	margin: 0;
	color: #0a0a0a;
	font-weight: 400;
`;

const CloseButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	color: ${COLORS.darkGray};
`;

const InputSection = styled.div`
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const StockInput = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Label = styled.label`
	display: block;
	margin-bottom: 8px;
	font-weight: 500;
	color: #747474;
`;

const Input = styled.input`
	width: 300px;
	padding: 10px;
	border: 2px solid #eeeeee;
	border-radius: 4px;
	font-size: 14px;
	background-color: #f8f8f8;
	color: ${COLORS.darkGray};
	&:focus {
		border-color: ${COLORS.primary};
		outline: none;
	}
	&::placeholder {
		color: #c3c3c3;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;

const CancelButton = styled.button`
	background: ${COLORS.lightGray};
	color: ${COLORS.darkGray};
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
`;

const ConfirmButton = styled.button`
	background: ${(props) =>
		props.disabled
			? COLORS.SECONDARY_TEXT
			: "linear-gradient(340.8deg, #FFDF17 18.2%, #FFF79E 167.34%)"};
	color: ${(props) => (props.disabled ? COLORS.LIGHT : COLORS.PRIMARY_TEXT)};
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	transition: background 0.3s ease, color 0.3s ease;
`;

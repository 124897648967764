import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { COLORS } from "../../constants";
import Pagination from "../general/Pagination";
import AttributeTable from "../includes/Attributes/AttributeTable";
import AddAttributesModal from "../includes/Attributes/Modals/AddAttributesModal";
import useGetApi from "../../hooks/useGetApi";
import { getToken } from "../utils/HelperFunctions";
import EditModal from "../includes/Attributes/Modals/EditModal";

function Attribute() {
    const dispatch = useDispatch();
    const [isModal, setModal] = useState(false);
    const [isEditModal, setEditModal] = useState(false);

    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        dispatch(updateActiveMenu("attributes"));
    }, []);

    const handleModal = () => {
        setModal((prev) => !prev);
    };

    const token = getToken();
    const {
        data: attributes,
        refetch,
        loading,
    } = useGetApi("shops/admin/attributes/", {
        page,
        page_size: itemsPerPage,
    });

    useEffect(() => {
        if (!isModal) {
            refetch();
        }
    }, [isModal]);

    const [selectedAttribute, setSelectedAttribute] = useState(null);

    const handleEdit = (attribute) => {
        setSelectedAttribute(attribute);
        setEditModal(true);
    };

    const refetchAttributes = () => {
        refetch(); // Ensure refetch is available
    };

    return (
        <Container>
            <AddAttributesModal isModal={isModal} handleModal={handleModal} />

            {isEditModal && (
                <EditModal
                    isEditModal={isEditModal}
                    setEditModal={setEditModal}
                    loading={loading}
                    selectedAttribute={selectedAttribute}
                    setSelectedAttribute={setSelectedAttribute}
                    refetch={refetchAttributes} // Pass the refetch function
                />
            )}
            <TopSection>
                <Title>Attributes</Title>
                <AddProductButton onClick={handleModal}>
                    <AddProductIcon />
                    <AddProductText>Add Attribute</AddProductText>
                </AddProductButton>
            </TopSection>
            <ContentSection>
                <TableContainer>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AttributeTable
                            attributes={attributes?.results}
                            handleModal={handleModal}
                            isEditModal={isEditModal}
                            setEditModal={setEditModal}
                            handleEdit={handleEdit}
                        />
                    )}
                </TableContainer>
                <Pagination
                    page={page}
                    pageCount={Math.ceil(attributes?.count / itemsPerPage)}
                    setPage={setPage}
                />
            </ContentSection>
        </Container>
    );
}

export default Attribute;

const Container = styled.div`
    position: relative;
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;
const Title = styled.h3`
    font-size: 18px;
    color: ${COLORS.PRIMARY_TEXT};
`;
const AddProductButton = styled.button`
    padding: 0px 20px;
    height: 40px;
    border-radius: 5px;
    background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;
const AddProductIcon = styled(AddOutlinedIcon)`
    font-size: 18px;
    color: ${COLORS.PRIMARY_TEXT};
`;
const AddProductText = styled.span`
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: ${COLORS.PRIMARY_TEXT};
`;
const ContentSection = styled.div`
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 5px;
    height: calc(100vh - 170px);
    padding-bottom: 90px;
    overflow-y: scroll;
`;
const TableContainer = styled.div``;

import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { API_BASE_URL, COLORS } from "../../../../constants";
import axios from "axios";
import { getToken } from "../../../utils/HelperFunctions";

function AddAttributesModal({ isModal, handleModal, refetchAttributes }) {
	const [title, setTitle] = useState("");
	const [values, setValues] = useState("");
	const [loading, setLoading] = useState(false);

	const token = getToken();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const response = await axios.post(
				`${API_BASE_URL}shops/admin/attributes/`,
				{
					title: title,
					value: values,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			console.log("Attribute added successfully:", response.data);
			setLoading(false);
			handleModal(); // Close the modal
			refetchAttributes(); // Refetch the attributes data after adding
		} catch (error) {
			console.error("Error adding attribute:", error.response?.data);
			setLoading(false);
		}
	};

	return (
		<ModalWrapper isModal={isModal}>
			<ModalContent>
				<ModalTopSection>
					<Title>Add New Attributes</Title>
					<CloseButton onClick={handleModal}>
						<CloseIcon />
					</CloseButton>
				</ModalTopSection>
				<form onSubmit={handleSubmit}>
					<InputSection>
						<Label htmlFor="attribute_name">Title</Label>
						<Input
							type="text"
							id="attribute_name"
							placeholder="Enter title"
							value={title}
							onChange={(e) => setTitle(e.target.value)} // Handle input change
						/>
					</InputSection>
					<InputSection>
						<Label htmlFor="attribute_value">Values</Label>
						<Textarea
							id="attribute_value"
							placeholder="Enter values"
							value={values}
							onChange={(e) => setValues(e.target.value)} // Handle textarea change
						/>
						<Description>
							Add multiple values separating using commas (,)
						</Description>
					</InputSection>
					<ButtonContainer>
						<CancelButton onClick={handleModal}>Cancel</CancelButton>
						<ConfirmButton type="submit" disabled={loading}>
							{loading ? "Submitting..." : "Submit"}
						</ConfirmButton>
					</ButtonContainer>
				</form>
			</ModalContent>
		</ModalWrapper>
	);
}

export default AddAttributesModal;

// Styled components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00011f33;
	backdrop-filter: blur(3px);
	display: ${(props) => (props.isModal ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	z-index: 99;
`;

const ModalContent = styled.div`
	background-color: #fff;
	padding: 30px;
	border-radius: 4px;
	width: 90%;
	max-width: 600px;
`;

const ModalTopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
	border-bottom: 1px solid ${COLORS.SECONDARY};
	margin-bottom: 30px;
`;

const Title = styled.h2`
	font-size: 20px;
	color: ${COLORS.PRIMARY_TEXT};
`;

const CloseButton = styled.button`
	background: none;
	border: none;
	color: #555;
	cursor: pointer;
	font-size: 1.25rem;

	&:hover {
		color: #000;
	}
`;

const InputSection = styled.div`
	margin-top: 1.5rem;
`;

const Label = styled.label`
	font-size: 15px;
	font-weight: bold;
	color: ${COLORS.SECONDARY_TEXT};
`;

const Input = styled.input`
	display: block;
	width: 100%;
	border: 1px solid ${COLORS.SECONDARY};
	margin-top: 0.5rem;
	padding: 0.25rem;
	height: 50px;
	border-radius: 6px;
	font-size: 16px;
	background-color: #f3f3f3;

	&:focus {
		outline: none;
	}
`;

const Textarea = styled.textarea`
	display: block;
	width: 100%;
	border: 1px solid ${COLORS.SECONDARY};
	margin-top: 0.5rem;
	padding: 10px;
	border-radius: 6px;
	font-size: 16px;
	background-color: #f3f3f3;
	height: 100px;

	&:focus {
		outline: none;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2.5rem;
`;

const CancelButton = styled.span`
	display: block;
	color: ${COLORS.SECONDARY_TEXT};
	padding: 0.5rem 1rem;
	cursor: pointer;
	margin-right: 1rem;

	&:hover {
		opacity: 0.8;
	}
`;

const ConfirmButton = styled.button`
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	color: ${COLORS.PRIMARY_TEXT};
	padding: 10px 35px;
	border-radius: 4px;
	border: none;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
`;

const Description = styled.p`
	font-size: 12px;
	margin-top: 5px;
	color: ${COLORS.SECONDARY_TEXT};
`;

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import CountCard from "../includes/Orders/CountCard";
import time from "../../assets/images/time.svg";
import cancel from "../../assets/images/clipboard-close.svg";
import returnIcon from "../../assets/images/return.svg";
import { API_BASE_URL, COLORS } from "../../constants";
import OrderDetailsTable from "../includes/Orders/OrderDetailsTable";
import Pagination from "../general/Pagination";
import useGetApi from "../../hooks/useGetApi";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import axios from "axios";
import { getToken } from "../utils/HelperFunctions";

function Order() {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const filterData = [
        { id: 1, name: "All" },
        { id: 2, name: "Pending" },
        { id: 3, name: "Accepted" },
        { id: 4, name: "Shipped" },
        { id: 5, name: "Delivered" },
        { id: 6, name: "Cancelled" },
        { id: 7, name: "Return" },
    ];
    const [activeFilter, setActiveFiter] = useState({ id: 1, name: "All" });
    useEffect(() => {
        dispatch(updateActiveMenu("orders"));
    }, []);
    // const [OrderData, setOrderData] = useState(data);
    const {
        data: OrderData,
        refetch,
        loading,
    } = useGetApi("orders/all-orders/", {
        page: page,
    });
    const [orderDataProducts, setOrderDataProducts] = useState(OrderData);

    useEffect(() => {
        if (OrderData) {
            setOrderDataProducts(OrderData);
        }
    }, [OrderData]);
    useEffect(() => {
        if (orderDataProducts?.count) {
            setPageCount(Math.ceil(orderDataProducts.count / 10));
        }
    }, [orderDataProducts]);

    const filteredData = useMemo(() => {
        if (activeFilter.name === "All") {
            return OrderData?.results || [];
        }
        if (activeFilter.name === "Pending") {
            return (OrderData?.results || []).filter(
                (order) =>
                    order.status === "ordered" || order.status === "pending"
            );
        }
        if (activeFilter.name === "Accepted")
            return (OrderData?.results || []).filter(
                (order) => order.status === "confirmed"
            );
        if (activeFilter.name === "Shipped")
            return (OrderData?.results || []).filter(
                (order) =>
                    order.status === "shipped" || order.status === "packed"
            );
        if (activeFilter.name === "Delivered")
            return (OrderData?.results || []).filter(
                (order) => order.status === "delivered"
            );
        if (activeFilter.name === "Cancelled")
            return (OrderData?.results || []).filter(
                (order) => order.status === "cancelled"
            );
        if (activeFilter.name === "Returned")
            return (OrderData?.results || []).filter(
                (order) =>
                    order.status === "return_dispatched" ||
                    order.status === "return_received"
            );
        return (OrderData?.results || []).filter(
            (order) => order.status === activeFilter.name.toLowerCase()
        );
    }, [activeFilter, OrderData]);

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: "#FFF199",
            },
            children: `${name
                ?.split(" ")
                .map((n) => n[0])
                .slice(0, 2)
                .join("")}`,
        };
    }
    const [popoverData, setPopoverData] = useState({
        isVisible: false,
        position: { top: 0, left: 20 },
    });
    const columns = useMemo(
        () => [
            {
                Header: "Sl No",
                accessor: "id",
                Cell: ({ cell }) => (
                    <div
                        style={{
                            width: "50px",
                        }}
                    >
                        {cell.row.index > 9
                            ? cell.row.index + 1
                            : `0${cell.row.index + 1}`}
                    </div>
                ),
            },
            {
                Header: "Order ID",
                accessor: "auto_id",
                Cell: ({ cell }) => (
                    <span>{"#" + cell.row.original.product_code}</span>
                ),
            },
            {
                Header: "Product Details",
                accessor: "name",
                Cell: ({ cell }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <div>
                            <h4
                                style={{
                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: 500,
                                }}
                            >
                                {cell.row.original.name}
                            </h4>
                            <h5
                                style={{
                                    fontSize: "16px",
                                    color: "#808080",
                                    fontWeight: 400,
                                }}
                            >
                                {cell.row.original.description}
                            </h5>
                        </div>
                    </div>
                ),
            },
            {
                Header: "Customer Details",
                accessor: "vendor_name",
                Cell: ({ cell }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <Avatar
                            {...stringAvatar(cell.row.original.vendor_name)}
                        />
                        <div>
                            <h4
                                style={{
                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: 500,
                                }}
                            >
                                {cell.row.original.vendor_name}
                            </h4>
                            <h5
                                style={{
                                    fontSize: "16px",
                                    color: "#808080",
                                    fontWeight: 400,
                                }}
                            >
                                {cell.row.original.vendor_phone}
                            </h5>
                        </div>
                    </div>
                ),
            },
            {
                Header: "Shipping Address",
                accessor: "shipping_address",
                Cell: ({ cell }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <div>
                            <h4
                                style={{
                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: 500,
                                }}
                            >
                                {cell.row.original.vendor_name}
                            </h4>
                            <h5
                                style={{
                                    fontSize: "16px",
                                    color: "#808080",
                                    fontWeight: 400,
                                }}
                            >
                                {cell.row.original.vendor_phone}
                            </h5>
                        </div>
                    </div>
                ),
            },
            {
                Header: "Date",
                accessor: "date",
                Cell: ({ cell }) => <span>{cell.value}</span>,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ cell }) => {
                    const status = cell.row.original.verification_status;

                    /*
					The admin verification statuses are as follows:
						pending
						reject
						approved
					*/
                    if (status === "confirmed") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EAF8F2",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#009262",
                                }}
                            >
                                <img src={confirm} alt="" />
                                Confirm Order
                            </div>
                        );
                    } else if (status === "ordered") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FFFFF0",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#FFB240",
                                }}
                            >
                                <img src={TimeIcon} alt="" />
                                Not Confirmed
                            </div>
                        );
                    } else if (status === "cancelled") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FCEAE8",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#E02B1D",
                                }}
                            >
                                <img src={CancelIcon} alt="" />
                                Cancelled Order
                            </div>
                        );
                    } else if (["shipped", "packed"].includes(status)) {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EDEEF4",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#5261C7",
                                }}
                            >
                                <img src={shipped} alt="" />
                                Shipped
                            </div>
                        );
                    } else if (
                        ["return_dispatched", "return_received"].includes(
                            status
                        )
                    ) {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FCEAE8",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#E02B1D",
                                }}
                            >
                                <img src={ReturnIcon} alt="" />
                                Returned Order
                            </div>
                        );
                    } else if (status === "delivered") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EAF8F2",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#009262",
                                }}
                            >
                                <img src={DeliveredIcon} alt="" />
                                Delivered
                            </div>
                        );
                    } else {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#fff",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#2f363d",
                                }}
                            >
                                <img src={UnknownIcon} alt="" />
                                Unknown Status
                            </div>
                        );
                    }
                },
            },
            {
                Header: "Action",
                accessor: "actions",
                Cell: () => (
                    <>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePopoverOpen(e);
                            }}
                        >
                            <MoreHorizIcon />
                        </Button>
                        {popoverData.isVisible && (
                            <div
                                className="custom-popover"
                                style={{
                                    top: `${popoverData.position.top}px`,
                                    left: `${popoverData.position.left}px`,
                                }}
                                onMouseLeave={handlePopoverClose}
                            >
                                {status === "ordered" && (
                                    <>
                                        <div
                                            className="popover-item popover-item-green"
                                            onClick={() =>
                                                console.log("Confirm Order")
                                            }
                                        >
                                            ✅ Confirm Order
                                        </div>
                                        <div
                                            className="popover-item popover-item-red"
                                            onClick={() =>
                                                console.log("Cancel Order")
                                            }
                                        >
                                            ❌ Cancel Order
                                        </div>
                                    </>
                                )}
                                {["shipped", "packed"].includes(status) && (
                                    <>
                                        <div
                                            className="popover-item popover-item-blue"
                                            onClick={() =>
                                                console.log("Download Slip")
                                            }
                                        >
                                            📥 Download Slip
                                        </div>
                                        <div
                                            className="popover-item popover-item-red"
                                            onClick={() =>
                                                console.log("Cancel Order")
                                            }
                                        >
                                            ❌ Cancel Order
                                        </div>
                                    </>
                                )}
                                {status === "confirmed" && (
                                    <>
                                        <div
                                            className="popover-item popover-item-green"
                                            onClick={() =>
                                                console.log("Ready to Ship")
                                            }
                                        >
                                            🚚 Ready to Ship
                                        </div>
                                        <div
                                            className="popover-item popover-item-blue"
                                            onClick={() =>
                                                console.log("Download Slip")
                                            }
                                        >
                                            📥 Download Slip
                                        </div>
                                        <div
                                            className="popover-item popover-item-red"
                                            onClick={() =>
                                                console.log("Cancel Order")
                                            }
                                        >
                                            ❌ Cancel Order
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                ),
            },
        ],
        [popoverData]
    );
    const handlePopoverOpen = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverData({
            isVisible: true,
            position: {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            },
        });
    };

    const handlePopoverClose = () => {
        setPopoverData({ isVisible: false, position: { top: 0, left: 0 } });
    };
    const [dataItem, setDataItem] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const token = getToken();
                const response = await axios.get(
                    `${API_BASE_URL}orders/order-aggregations/`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`, // Add token here
                        },
                    }
                );

                setDataItem(response.data);
            } catch (error) {
                console.error("Error fetching order aggregations:", error);
            }
        }
        fetchData();
    }, []);

    return (
        <Container>
            <Title>Orders</Title>
            <CountSection>
                <CountCard
                    icon={time}
                    label="pending orders"
                    count={dataItem?.pending_orders_count}
                />
                <CountCard
                    icon={cancel}
                    label="cancel orders"
                    count={dataItem?.cancelled_orders_count}
                />
                <CountCard
                    icon={returnIcon}
                    label="returned orders"
                    count={dataItem?.returned_orders_count}
                />
            </CountSection>
            <OrderTableSection>
                <FilterMenu>
                    {filterData?.map((item) => (
                        <Menu
                            className={
                                activeFilter.id === item.id ? "active" : ""
                            }
                            key={item.id}
                            onClick={() => setActiveFiter(item)}
                        >
                            {item.name}
                        </Menu>
                    ))}
                </FilterMenu>
                <OrderDetailsTable
                    columns={columns}
                    data={filteredData}
                    fetchData={refetch}
                    loading={loading}
                    pageCount={pageCount}
                    totalPages={pageCount}
                    setPage={setPage}
                    currentPage={page}
                    OrderData={OrderData}
                    orderDataProducts={orderDataProducts}
                    setOrderDataProducts={setOrderDataProducts}
                />
                <Pagination
                    page={page}
                    pageCount={pageCount}
                    setPage={setPage}
                    OrderData={OrderData}
                />
            </OrderTableSection>
        </Container>
    );
}

export default Order;
const Container = styled.div`
    height: 100%;
`;
const Title = styled.h2`
    font-size: 18px;
    margin-bottom: 10px;
`;
const CountSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
`;
const OrderTableSection = styled.div`
    overflow: scroll;
    position: relative;
    /* position: sticky;
	top: 0px;
	z-index: 999; */
`;
const FilterMenu = styled.div`
    background-color: ${COLORS.PRIMARY};
    padding: 10px 16px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-bottom: 10px;
    position: -webkit-sticky; /* Safari */
`;
const Menu = styled.span`
    padding: 6px 16px;
    display: block;
    border-radius: 3px;
    transition: 0.3s;
    font-size: 14px;
    text-transform: capitalize;
    color: ${COLORS.SECONDARY_TEXT};
    cursor: pointer;
    &.active {
        background-color: ${COLORS.THEME_COLOR};
        color: ${COLORS.PRIMARY_TEXT};
    }
    &:hover {
        background-color: ${COLORS.THEME_COLOR};
        color: ${COLORS.PRIMARY_TEXT};
    }
`;

import "./assets/css/App.css";
import "./assets/css/ToastStyles.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./components/routers/MainRouter";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

function App() {
	return (
		<div className="App">
			<Router>
				<ToastContainer
					progressClassName="custom-progress-bar"
					icon={<span className="custom-tick">✔</span>}
				/>
				<MainRouter />
			</Router>
		</div>
	);
}

export default App;

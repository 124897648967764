import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { API_BASE_URL, COLORS } from "../../../../constants";
import axios from "axios";
import { getToken } from "../../../utils/HelperFunctions";

function EditModal({
    isEditModal,
    setEditModal,
    loading,
    selectedAttribute,
    setSelectedAttribute,
    refetch,
}) {
    const [title, setTitle] = useState("");
    const [values, setValues] = useState("");

    useEffect(() => {
        if (selectedAttribute) {
            setTitle(selectedAttribute.title || "");
            setValues(selectedAttribute?.value || "");
        }
    }, [selectedAttribute]);

    const token = getToken();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedData = {
            title: title.trim(),
            value: values, // Convert comma-separated values into an array
        };

        try {
            const response = await axios.patch(
                `${API_BASE_URL}shops/admin/attributes/${selectedAttribute.id}/`,
                updatedData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(response, "pppp");

            if (response.status === 200) {
                console.log("Update successful:", response.data);
                setSelectedAttribute(null);
                setEditModal(false);
                refetch();
            }
        } catch (error) {
            console.error("Error during update:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <ModalWrapper isEditModal={isEditModal}>
            <ModalContent>
                <ModalTopSection>
                    <Title>Edit Attribute</Title>
                    <CloseButton onClick={() => setEditModal(false)}>
                        <CloseIcon />
                    </CloseButton>
                </ModalTopSection>
                <form onSubmit={handleSubmit}>
                    <InputSection>
                        <Label htmlFor="title">Title</Label>
                        <Input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </InputSection>
                    <InputSection>
                        <Label htmlFor="values">Values</Label>
                        <Textarea
                            id="values"
                            value={values}
                            onChange={(e) => setValues(e.target.value)}
                        />
                        <Description>
                            Add multiple values separating using commas (,)
                        </Description>
                    </InputSection>
                    <ButtonContainer>
                        <CancelButton onClick={() => setEditModal(false)}>
                            Cancel
                        </CancelButton>
                        <ConfirmButton type="submit" disabled={loading}>
                            {loading ? "Submitting..." : "Submit"}
                        </ConfirmButton>
                    </ButtonContainer>
                </form>
            </ModalContent>
        </ModalWrapper>
    );
}

export default EditModal;

// Styled components
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00011f33;
    backdrop-filter: blur(3px);
    display: ${(props) => (props.isEditModal ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    z-index: 99;
`;

const ModalContent = styled.div`
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
    width: 90%;
    max-width: 600px;
`;

const ModalTopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${COLORS.SECONDARY};
    margin-bottom: 30px;
`;

const Title = styled.h2`
    font-size: 20px;
    color: ${COLORS.PRIMARY_TEXT};
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: #555;
    cursor: pointer;
    font-size: 1.25rem;

    &:hover {
        color: #000;
    }
`;

const InputSection = styled.div`
    margin-top: 1.5rem;
`;

const Label = styled.label`
    font-size: 15px;
    font-weight: bold;
    color: ${COLORS.SECONDARY_TEXT};
`;

const Input = styled.input`
    display: block;
    width: 100%;
    border: 1px solid ${COLORS.SECONDARY};
    margin-top: 0.5rem;
    padding: 0.25rem;
    height: 50px;
    border-radius: 6px;
    font-size: 16px;
    background-color: #f3f3f3;

    &:focus {
        outline: none;
    }
`;

const Textarea = styled.textarea`
    display: block;
    width: 100%;
    border: 1px solid ${COLORS.SECONDARY};
    margin-top: 0.5rem;
    padding: 10px;
    border-radius: 6px;
    font-size: 16px;
    background-color: #f3f3f3;
    height: 100px;

    &:focus {
        outline: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
`;

const CancelButton = styled.span`
    display: block;
    color: ${COLORS.SECONDARY_TEXT};
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-right: 1rem;

    &:hover {
        opacity: 0.8;
    }
`;

const ConfirmButton = styled.button`
    background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
    color: ${COLORS.PRIMARY_TEXT};
    padding: 10px 35px;
    border-radius: 4px;
    border: none;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const Description = styled.p`
    font-size: 12px;
    margin-top: 5px;
    color: ${COLORS.SECONDARY_TEXT};
`;

import React from "react";
import styled from "styled-components";

function ToastMessage({ icon, message }) {
	return (
		<Container>
			<Icon>
				<img src={icon} alt="icon" />
			</Icon>
			<p>{message}</p>
		</Container>
	);
}

export default ToastMessage;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Icon = styled.span`
	display: block;
	width: 30px;
	margin-right: 10px;
	img {
		display: block;
		width: 100%;
	}
`;

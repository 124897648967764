import React, { useState } from "react";
import styled from "styled-components";
import action from "../../../assets/images/more.svg";
import ToggleSwitch from "./ToggleSwitch";
import axiosInstance from "../../../api";

function ProductActionButton({
    data,
    selectedProduct,
    setSelectedProduct,
    isChecked,
    disabled,
    toggled,
    setToggled,
    handleToggle,
}) {
    return (
        <Container>
            <ToggleSwitch
                toggled={toggled}
                setToggled={setToggled}
                handleToggle={handleToggle}
                disabled={disabled}
            />
            <Button
                onClick={() => {
                    setSelectedProduct(data.id);
                }}
            >
                <img src={action} alt="" />
            </Button>
        </Container>
    );
}

export default ProductActionButton;
const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Button = styled.span`
    display: block;
    width: 30px;
    transition: 0.1s;
    img {
        display: block;
        width: 100%;
    }
    :hover {
        opacity: 0.5;
    }
`;

import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ProductInputSection from "./ProductInputSection";

function AddProductDetails({ setCurrentPage }) {
    return (
        <Container>
            <TopSection>
                <LeftSection>
                    <Title>Add Products</Title>
                    <Label>Products/add products</Label>
                </LeftSection>
                <ProgressBar>
                    <ProgressLabel>
                        <Tick />
                    </ProgressLabel>
                    <ProgressLabel>2</ProgressLabel>
                </ProgressBar>
            </TopSection>
            <Cover>
                <ProductLabelAndButtonSection>
                    <ProductLabel>Product 1</ProductLabel>
                    <AddProductButton>
                        <AddProductIcon />
                        <AddProductText>Add Product</AddProductText>
                    </AddProductButton>
                </ProductLabelAndButtonSection>
                <ProductInputSection />
            </Cover>
        </Container>
    );
}

export default AddProductDetails;
const Container = styled.div`
    margin: 0 auto;
    box-sizing: border-box;
    min-width: 1100px;
    ::-webkit-scrollbar {
        display: none !important;
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
`;
const LeftSection = styled.div``;

const Title = styled.h2`
    font-size: 20px;
    color: ${COLORS.PRIMARY_TEXT};
`;
const Label = styled.p`
    color: ${COLORS.SECONDARY_TEXT};
    font-size: 13px;
`;
const ProgressBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const ProgressLabel = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: ${COLORS.PRIMARY_TEXT};
    border: none;
    border-radius: 5px;
    position: relative;

    background: ${COLORS.THEME_GRADIENT};
    :last-child {
        color: #d4d4d4;
        border: 2px solid ${COLORS.SECONDARY};
        margin-left: 50px;
        background: ${COLORS.PRIMARY};
        ::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-bottom: 2px dashed ${COLORS.THEME_COLOR};
            width: 50px;
            left: -50px;
            box-sizing: border-box;
        }
    }
    .css-i4bv87-MuiSvgIcon-root {
        /* font-size: 14px; */
    }
`;
const Tick = styled(DoneOutlinedIcon)`
    color: ${COLORS.PRIMARY_TEXT};
    display: block;
    width: 14px;
    font-size: 16px !important;
`;
const Cover = styled.div`
    background: #fff;
    padding: 30px;
    margin-top: 20px;
`;
const ProductLabelAndButtonSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;
`;
const ProductLabel = styled.p``;
const AddProductButton = styled.button`
    padding: 0px 10px;
    height: 40px;
    border-radius: 5px;
    border: 2px dashed ${COLORS.THEME_COLOR};
    background-color: #fff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const AddProductIcon = styled(AddOutlinedIcon)`
    font-size: 16px;
    color: ${COLORS.THEME_COLOR};
`;

const AddProductText = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${COLORS.THEME_COLOR};
`;

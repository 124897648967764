import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import ResendOtp from "./ResentOtp";
import SIgnInRightSection from "./SIgnInRightSection";

function EnterOtp() {
	const navigate = useNavigate();
	const userData = useSelector((state) => state.user.userData);
	const [otp, setOtp] = useState(["", "", "", ""]);
	const [isError, setError] = useState(false);

	const [errorMessage, setErrorMessage] = useState(
		"The OTP you entered is wrong"
	);
	const refs = [useRef(), useRef(), useRef(), useRef()];

	function handleChange(e, index) {
		setError(false);
		const value = e.target.value.replace(/[^\d]/, "");
		setOtp((prev) => {
			const current = [...prev];
			current[index] = value;

			if (value && index < refs.length - 1) {
				refs[index + 1].current.focus();
			}

			if (!value && index > 0) {
				refs[index - 1].current.focus();
			}

			return current;
		});
	}

	const changePhone = () => {
		navigate("/auth/login");
	};
	function convertOtpToString(otpArr) {
		return otpArr.join("");
	}
	const handleSubmit = () => {
		console.log(convertOtpToString(otp), "submited");
	};

	return (
		<Container>
			<LeftSection>
				<Cover>
					<Title name={"sree"}>OTP Verification </Title>

					<Description>
						We have send you an OTP code entered number <br />
						{userData.phone}
						<Link onClick={changePhone}> &nbsp; Change Number</Link>
					</Description>
					<OptContainer>
						<OptInput
							placeholder="0"
							ref={refs[0]}
							maxLength="1"
							value={otp[0]}
							onChange={(e) => handleChange(e, 0)}
						/>
						<OptInput
							placeholder="0"
							ref={refs[1]}
							maxLength="1"
							value={otp[1]}
							onChange={(e) => handleChange(e, 1)}
						/>
						<OptInput
							placeholder="0"
							ref={refs[2]}
							maxLength="1"
							value={otp[2]}
							onChange={(e) => handleChange(e, 2)}
						/>
						<OptInput
							placeholder="0"
							ref={refs[3]}
							maxLength="1"
							value={otp[3]}
							onChange={(e) => handleChange(e, 3)}
						/>
						{isError && <Error>{errorMessage}</Error>}
					</OptContainer>
					<ResentOtpContainer>
						<ResendOtp />
					</ResentOtpContainer>
					<SendOTPButton onClick={handleSubmit}>Verify</SendOTPButton>
					<AgreementLabel>
						By continuing, you agree to onerupee’s
						<br />
						<PolicyLink>Terms & Conditions</PolicyLink> &{" "}
						<PolicyLink>Privacy Policy</PolicyLink>
					</AgreementLabel>
					<Title></Title>
				</Cover>
			</LeftSection>
			<RightSection>
				<SIgnInRightSection />
			</RightSection>
		</Container>
	);
}

export default EnterOtp;
const Container = styled.div`
	display: grid;
	grid-template-columns: 6fr 5fr;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;
`;

const LeftSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex: 1;
	background-color: #fffcf7;
	position: relative;
	padding-top: 20%;
`;
const Cover = styled.div`
	max-width: 400px;
	width: 100%;
`;

const RightSection = styled.div``;
const OptContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	justify-content: space-around;
	position: relative;
`;

const OptInput = styled.input`
	width: 90px;
	height: 80px;
	display: block;
	border: none;
	font-size: 40px;
	border-bottom: 2px solid #b4b4b4;
	text-align: center;
	background-color: transparent;
	color: ${COLORS.PRIMARY_TEXT};
	::placeholder {
		color: #dadada;
	}

	&:focus {
		outline: none;
		border-color: #eab600;
	}
`;
const Title = styled.h1`
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 10px;
	text-align: center;
	color: ${COLORS.PRIMARY_TEXT};
`;

const Description = styled.p`
	font-size: 16px;
	margin-bottom: 20px;
	text-align: center;
	color: ${COLORS.SECONDARY_TEXT};
	margin-bottom: 80px;
`;
const Link = styled.span`
	display: inline-block;
	color: #e48900;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const ResentOtpContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
`;
const SendOTPButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 0 20px;
	font-size: 16px;
	font-weight: bold;
	margin-top: 50px;
	color: ${COLORS.PRIMARY_TEXT};
	background: linear-gradient(340.8deg, #ffdf17 18.2%, #fff79e 167.34%);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		/* transform: translateY(-3px); */
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
	}
`;
const AgreementLabel = styled.label`
	font-size: 14px;
	color: grey;
	margin-top: 30px;
	position: absolute;
	bottom: 50px;
	max-width: 300px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
`;

const PolicyLink = styled.a`
	color: #eab600;
	text-decoration: underline;
`;
const Error = styled.p`
	position: absolute;
	font-size: 13px;
	color: red;
	bottom: -20px;
	left: 0px;
`;

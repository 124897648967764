import React, { useState, useEffect, useMemo } from "react";
import useGetApi from "../../hooks/useGetApi";
import Table from "../general/Table";
import styled from "styled-components";
import { Avatar, Button } from "@mui/material";
import { ClassNames } from "@emotion/react";
import TimeIcon from "../../assets/images/timeCont.svg";
import TickSquare from "../../assets/images/tick-square.svg";
import ShippedIcon from "../../assets/images/admin_delivery.svg";
import CancelledIcon from "../../assets/images/cancel.svg";
import ReturnIcon from "../../assets/images/order-return-admin.svg";
import DeliveredIcon from "../../assets/images/order-delivered-admin.svg";
import axiosInstance from "../../api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const VendorOrders = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateActiveMenu("Vendor Orders"));
    }, []);
    const filterData = [
        {
            id: 1,
            name: "All",
        },
        {
            id: 2,
            name: "Pending",
        },
        {
            id: 3,
            name: "Accepted",
        },
        {
            id: 4,
            name: "Shipped",
        },
        {
            id: 5,
            name: "Delivered",
        },
        {
            id: 6,
            name: "Cancelled",
        },
        {
            id: 7,
            name: "Returned",
        },
    ];
    const [activeFilter, setActiveFilter] = useState({ id: 1, name: "All" });
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: "#FFF199",
            },
            children: `${name
                ?.split(" ")
                .map((n) => n[0])
                .slice(0, 2)
                .join("")}`,
        };
    }
    const handlePopoverOpen = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverData({
            isVisible: true,
            position: {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            },
        });
    };
    const {
        data: vendorData,
        refetch,
        loading,
    } = useGetApi("orders/vendor-orders/", { page: page });
    useEffect(() => {
        if (vendorData?.count) {
            setPageCount(Math.ceil(vendorData.count / 6)); // Assuming page size is 6
        }
    }, [vendorData]);
    const filteredData = useMemo(() => {
        if (activeFilter.name === "All") {
            return vendorData?.results || [];
        }
        if (activeFilter.name === "Pending") {
            return (vendorData?.results || []).filter(
                (vendor) => vendor.status === "pending"
            );
        }
        if (activeFilter.name === "Accepted") {
            return (vendorData?.results || []).filter(
                (vendor) =>
                    vendor.status === "confirmed" || vendor.status === "packed"
            );
        }
        if (activeFilter.name === "Shipped") {
            return (vendorData?.results || []).filter(
                (vendor) => vendor.status === "shipped"
            );
        }
        if (activeFilter.name === "Delivered") {
            return (vendorData?.results || []).filter(
                (vendor) => vendor.status === "delivered"
            );
        }
        if (activeFilter.name === "Cancelled") {
            return (vendorData?.results || []).filter(
                (vendor) => vendor.status === "cancelled"
            );
        }
        if (activeFilter.name === "Returned") {
            return (vendorData?.results || []).filter(
                (vendor) => vendor.status === "returned"
            );
        }
        return (vendorData?.results || []).filter(
            (vendor) => vendor.item_status === activeFilter.name.toLowerCase()
        );
    }, [activeFilter, vendorData]);
    const columns = useMemo(
        () => [
            {
                Header: "Sl No",
                accessor: "id",
                Cell: ({ cell }) => {
                    return <span>{cell.row.index + 1}</span>;
                },
            },
            {
                Header: "Product Detail",
                accessor: "product_detail",
                Cell: ({ cell }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                width: "330px",
                            }}
                        >
                            <div>
                                <h4
                                    style={{
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: 500,
                                    }}
                                >
                                    {cell.row.original.product_name}
                                </h4>
                                <h5
                                    style={{
                                        fontSize: "16px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {cell.row.original.product_description}
                                </h5>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Customer Detail",
                accessor: "customer_name",
                Cell: ({ cell }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Avatar
                                {...stringAvatar(
                                    cell.row.original.customer_data.name
                                )}
                            />
                            <div>
                                <h4
                                    style={{
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: 500,
                                    }}
                                >
                                    {cell.row.original.customer_data.name}
                                </h4>
                                <h5
                                    style={{
                                        fontSize: "16px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {cell.row.original.customer_data.phone}
                                </h5>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Vendor Detail",
                accessor: "name",
                Cell: ({ cell }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Avatar
                                {...stringAvatar(
                                    cell.row.original.vendor_data.vendor_name
                                )}
                            />
                            <div>
                                <h4
                                    style={{
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: 500,
                                    }}
                                >
                                    {cell.row.original.vendor_data.vendor_name}
                                </h4>
                                <h5
                                    style={{
                                        fontSize: "16px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {cell.row.original.vendor_data.vendor_phone}
                                </h5>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Date",
                accessor: "date",
                Cell: ({ cell }) => {
                    return <span>{cell.value}</span>;
                },
            },
            {
                Header: "Status",
                accessor: "all_status",
                Cell: ({ cell }) => {
                    const status = cell.row.original.status;
                    /*
                    Available statuses:

                    // statuses managed by customer
                    ordered # customer order places by payment completion
                    return_dispatched
                    return_received
                    cancelled

                    // statuses managed by vendor
                    confirmed 
                    packed
                    shipped

                    // managed by delivery partner
                    delivered
                    shipment_failed TODO: get icons for this
                    */

                    if (status === "confirmed") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EAF8F2",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#009262",
                                }}
                            >
                                <img src={TickSquare} alt="" />
                                Accepted Order
                            </div>
                        );
                    } else if (status === "ordered") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FFFFF0",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#FFB240",
                                }}
                            >
                                <img src={TimeIcon} alt="" />
                                Pending
                            </div>
                        );
                    } else if (status === "cancelled") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FCEAE8",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#E02B1D",
                                }}
                            >
                                <img src={CancelledIcon} alt="" />
                                Cancelled Order
                            </div>
                        );
                    } else if (["shipped", "packed"].includes(status)) {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EDEEF4",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#5261C7",
                                    textTransform: "capitalize",
                                }}
                            >
                                <img src={ShippedIcon} alt="" />
                                {status}
                            </div>
                        );
                    } else if (
                        ["return_dispatched", "return_received"].includes(
                            status
                        )
                    ) {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FCEAE8",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#E02B1D",
                                    textTransform: "capitalize",
                                }}
                            >
                                <img src={ReturnIcon} alt="" />
                                Returned Order
                            </div>
                        );
                    } else if (status === "delivered") {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EAF8F2",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    color: "#009262",
                                }}
                            >
                                <img src={DeliveredIcon} alt="" />
                                Delivered
                            </div>
                        );
                    }
                },
            },
        ],
        []
    );

    return (
        <div>
            <TopContainer>
                {filterData.map((item) => (
                    <Button
                        onClick={() => setActiveFilter(item)}
                        sx={{
                            backgroundColor:
                                activeFilter.id === item.id ? "#FFF2A1" : null,
                            color: "#000",
                        }}
                        style={{ textTransform: "capitalize" }}
                    >
                        {item.name}
                    </Button>
                ))}
            </TopContainer>
            <Table
                columns={columns}
                data={filteredData}
                fetchData={refetch}
                loading={loading}
                pageCount={filteredData?.count}
                totalPages={filteredData?.count % 6}
                setPage={setPage}
                currentPage={page}
            />
        </div>
    );
};

export default VendorOrders;

const TopContainer = styled.div`
    width: 100%;
    padding: 20px 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    gap: 20px;
`;

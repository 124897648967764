import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, COLORS } from "../../../constants";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axiosInstance from "../../../api";
import { getToken } from "../../utils/HelperFunctions";
import UploadImageModal from "./UploadImageModal";

function SelectCategory({ setCurrentPage }) {
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const {
		control,
		handleSubmit,
		setError,
		watch,
		formState: { errors },
	} = useForm();
	const token = getToken();
	const [selectedCategories, setSelectedCategories] = useState([null]); // Initialize with one empty value for the first select box
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const fetchCategories = async () => {
			setLoading(true);
			try {
				const response = await axiosInstance.get(
					API_BASE_URL + "shops/admin/category-tree/",
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);
				if (response.data) {
					setCategories(response.data);
				}
			} catch (error) {
				console.error("Error fetching categories:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchCategories();
	}, [token]);

	function fetchSubcategories(level) {
		let subcategories = categories;
		for (let i = 0; i < level; i++) {
			const selectedPk = selectedCategories[i];
			const foundCategory = subcategories.find((cat) => cat.pk === selectedPk);
			if (foundCategory) {
				subcategories = foundCategory.sub_categories || [];
			}
		}
		return subcategories;
	}

	function handleCategoryChange(level, selectedValue) {
		const subcategories = fetchSubcategories(level); // Fetch next subcategories
		const updatedCategories = [...selectedCategories];
		updatedCategories[level] = selectedValue;

		// Remove subsequent categories if no subcategories exist at the next level
		if (subcategories && subcategories.length > 0) {
			updatedCategories[level + 1] = null; // Reset the next level categohhry
		} else {
			updatedCategories.splice(level + 1); // Trim the categories if no subcategories
			updatedCategories.push(null); // Add a null value for the next category
		}

		setSelectedCategories(updatedCategories);
	}

	const onSubmit = (data) => {
		let hasError = false;
		if (!selectedCategories.length) {
			setError("category0", {
				type: "manual",
				message: "You must select a category",
			});
			hasError = true;
		}
		if (!hasError) {
			setIsOpen(true);
		}
	};

	const [coverImage, setCoverImage] = useState(null);
	const [isUploadCoverModel, setUploadCoverModal] = useState(false);

	const handleCoverModal = () => {
		setUploadCoverModal((prev) => !prev);
	};

	const handleCoverSubmit = (data) => {
		setCoverImage(data);
		handleCoverModal();
	};

	return (
		<Container>
			<TopSection>
				<LeftSection>
					<Title>Add Products</Title>
					<Label>Products/add products</Label>
				</LeftSection>
				<ProgressBar>
					<ProgressLabel>1</ProgressLabel>
					<ProgressLabel>2</ProgressLabel>
				</ProgressBar>
			</TopSection>
			<SelectContainer>
				<SubTitle>Categories</SubTitle>
				<SelectSection onSubmit={handleSubmit(onSubmit)}>
					{selectedCategories.map((_, level) => {
						const subcategories = fetchSubcategories(level);

						if (subcategories.length === 0) {
							return null;
						}

						return (
							<SelectOption key={level}>
								<SelectLabel>
									{level === 0 ? "Select Category" : `Category ${level}`}
								</SelectLabel>
								<Controller
									name={`category${level}`}
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<select
											value={value}
											onChange={(e) => {
												const selectedSubcategory = subcategories.find(
													(subcategory) => subcategory.pk === e.target.value
												);

												onChange(e);
												handleCategoryChange(level, e.target.value);

												if (selectedSubcategory) {
													localStorage.setItem(
														"lastSubcategory",
														JSON.stringify(selectedSubcategory)
													);
												}
											}}
											disabled={loading || subcategories.length === 0}
										>
											<option value="">Select</option>
											{subcategories.map((subcategory) => (
												<option key={subcategory.pk} value={subcategory.pk}>
													{subcategory.title}
												</option>
											))}
										</select>
									)}
								/>
							</SelectOption>
						);
					})}

					<ButtonContainer>
						<CancelButton>Cancel</CancelButton>
						<SubmitButton type="submit">Submit</SubmitButton>
					</ButtonContainer>
				</SelectSection>
			</SelectContainer>
			{isOpen && (
				<UploadImageModal
					title="Upload Image"
					isOpen={isOpen}
					close={() => setIsOpen(false)}
					handleSubmit={handleCoverSubmit}
					setCurrentPage={setCurrentPage}
					imageType="cover-image"
				/>
			)}
		</Container>
	);
}

export default SelectCategory;

const Container = styled.div`
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	margin-top: 30px;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
`;
const LeftSection = styled.div``;
const Title = styled.h2`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
`;
const Label = styled.p`
	color: ${COLORS.SECONDARY_TEXT};
	font-size: 13px;
`;
const ProgressBar = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
const ProgressLabel = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	border: 1px solid ${COLORS.THEME_COLOR};
	border-radius: 5px;
	position: relative;
	background-color: ${COLORS.PRIMARY};
	:last-child {
		color: #d4d4d4;
		border: 1px solid ${COLORS.SECONDARY};
		margin-left: 50px;
		::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			border-bottom: 2px dashed ${COLORS.THEME_COLOR};
			width: 50px;
			left: -50px;
			box-sizing: border-box;
		}
	}
`;
const SelectContainer = styled.div`
	padding: 30px;
	background-color: ${COLORS.PRIMARY};
	margin-top: 20px;
`;
const SubTitle = styled.h3`
	font-size: 18px;
	color: ${COLORS.PRIMARY_TEXT};
	padding-bottom: 20px;
	border-bottom: 2px solid ${COLORS.SECONDARY};
	margin-bottom: 30px;
`;
const SelectSection = styled.form`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 30px;
`;
const SelectLabel = styled.label`
	justify-self: flex-start;
	color: ${COLORS.SECONDARY_TEXT};
	min-width: 120px;
`;
const SelectOption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	select {
		padding: 14px 10px;
		background-color: ${COLORS.SECONDARY};
		width: 100%;
		max-width: 600px;
		border: 2px solid #eeeeee;
		border-radius: 5px;
		:focus {
			border-color: #eab600;
		}
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
`;
const CancelButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;
const ErrorMessage = styled.span`
	color: red;
	font-size: 12px;
	margin-top: 5px;
	display: block;
	position: absolute;
	left: 30px;
	bottom: -22px;
`;

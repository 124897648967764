import React, { useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, COLORS } from "../../../constants";
import upload_icon from "../../../assets/images/upload_img.svg";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OutsideClick from "../../general/OutSideClick";
import axiosInstance from "../../../api";
const UploadImageModal = ({
	title,
	isOpen,
	close,
	handleSubmit,
	setCurrentPage,
	imageType,
}) => {
	const [file, setFile] = useState(null);
	const [file_url, setFileUrl] = useState(null);
	const [progress, setProgress] = useState(0);

	const handleFileInputChange = async (event) => {
		const file = event.target.files[0];
		if (!file) return;
		if (imageType == "cover-image") {
			localStorage.removeItem("coverImageUrl");
		}
		setProgress(0); // Reset progress before upload
		setFile(file);

		const formData = new FormData();
		formData.append("product_image", file, file.name);

		try {
			const response = await axiosInstance
				.post(`${API_BASE_URL}shops/upload-product-image/`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						setProgress(percentCompleted);
					},
				})
				.then((res) => {
					setFileUrl(res.data.data?.url);
					if (imageType == "cover-image") {
						localStorage.setItem("coverImageUrl", res.data.data?.url);
					}
				});
		} catch (error) {
			console.error("Upload failed:", error);
		}
	};

	const handleUploadClick = () => {
		if (!file) {
			alert("No file selected.");
			return;
		}

		handleSubmit(file_url);
		setFile(null);
		setCurrentPage("add-product");
	};

	return (
		<ModalBackdrop isOpen={isOpen}>
			<OutsideClick onClickOutside={close}>
				<ModalContent>
					<Title>{title}</Title>
					{!file && (
						<InputWrapper>
							<UploadIcon>
								<img src={upload_icon} alt="Upload icon" />
							</UploadIcon>
							<Text>
								Drag and Drop photos or videos here. you must upload .jpg .png{" "}
							</Text>
							<Button>Browse File</Button>

							<input
								id="file-input"
								type="file"
								accept="image/*"
								onChange={handleFileInputChange}
							/>
						</InputWrapper>
					)}

					{/* {file && progress === 0 && (
					<button onClick={handleUploadClick}>Upload</button>
				)} */}

					{progress > 0 && progress < 100 && (
						<ProgressCover>
							<CircularProgressbar
								value={progress}
								text={`${progress}%`}
								styles={{
									path: {
										// Path color
										stroke: "#eace17",
										// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
										strokeLinecap: "butt",
										// Customize transition animation
										transition: "stroke-dashoffset 0.5s ease 0s",
										// Rotate the path
										transform: "rotate(0.25turn)",
										transformOrigin: "center center",
									},
									background: {
										fill: "#ffffff ",
									},
									text: {
										fill: `#fff`,
										// Text size
									},
								}}
							/>
						</ProgressCover>
					)}

					{file && (
						<ImagePreview
							isVisble={progress === 100 && true}
							src={file_url}
							alt="Uploaded image"
						/>
					)}
					<ButtonContainer>
						{" "}
						<CancelButton onClick={close}>Cancel</CancelButton>
						<SubmitButton onClick={handleUploadClick}>Submit</SubmitButton>
					</ButtonContainer>
				</ModalContent>
			</OutsideClick>
		</ModalBackdrop>
	);
};

export default UploadImageModal;
const ModalBackdrop = styled.div`
	background-color: rgba(0, 1, 31, 0.2);
	backdrop-filter: blur(3px);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const ModalContent = styled.div`
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 30px;
	text-align: center;
	max-width: 700px;
	width: 100%;
`;

const ProgressBar = styled.progress`
	width: 90%;
	max-width: 400px;
	margin-top: 16px;
`;

const ImagePreview = styled.img`
	width: 100%;
	margin-top: 16px;
	max-height: 50vh;
	object-fit: contain;
	filter: blur(${(props) => (props.isVisble ? 0 : "5px")});
	/* blur(3px); */
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 80px;
	border: 3px dashed #d4d4d4;
	border-radius: 5px;
	input {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		height: 100%;
		opacity: 0;
	}
`;
const Title = styled.h2`
	font-size: 20px;
	color: ${COLORS.PRIMARY_TEXT};
	text-align: left;
	margin-bottom: 20px;
`;
const UploadIcon = styled.span`
	display: block;
	width: 70px;
	margin-bottom: 30px;
	img {
		display: block;
		width: 100%;
	}
`;
const Text = styled.p`
	font-size: 15px;
	color: ${COLORS.SECONDARY_TEXT};
	max-width: 300px;
	margin-bottom: 10px;
`;
const Button = styled.p`
	color: rgba(234, 182, 0, 1);
	font-size: 16px;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	padding-top: 20px;
`;
const CancelButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
	cursor: pointer;
`;
const SubmitButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;
const ProgressCover = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #99999976;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	svg {
		max-width: 200px;
	}
`;

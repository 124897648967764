import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getToken } from "../../utils/HelperFunctions";
import axiosInstance from "../../../api";
import { COLORS } from "../../../constants";
import { Link } from "react-router-dom";
import SuccessModal from "./SuccessModal";
import CustomCheckbox from "./CustomCheckbox";
import ToastSucessMessage from "../../general/Auth/ToastSucessMessage";
import cross from "../../../assets/images/cancel.svg";

function InputSection() {
	const [isChecked, setIsChecked] = useState(false);

	const [productDetails, setProductDetails] = useState({});
	const [toggled, setToggled] = useState(false);
	const [attributeList, setAttributeList] = useState([]);
	const [subOptions, setSubOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [quantityValues, setQuantityValues] = useState({});
	const [modal, setModal] = useState(false);
	const [brands, setBrands] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [attributes, setAttributes] = useState([]);
	const [enableAttributeQty, setEnableAttributeQty] = useState(false);
	const [sku, setSku] = useState("");
	const lastSubCategory = localStorage.getItem("lastSubcategory")
		? JSON.parse(localStorage.getItem("lastSubcategory"))
		: null;
	const [success, setSuccess] = useState(false);
	const [productId, setProductId] = useState("");
	const schema = Yup.object({
		name: Yup.string().required("Product Name is Required"),
		description: Yup.string(),
		brand: Yup.string(),
		attribute_sku: Yup.string(),
		hsn_code: Yup.string().required(),
		return: Yup.string().required(),
		warranty: Yup.string().required(),
		actual_cost: Yup.number().required("Select a valid price").positive(),
		display_cost: Yup.number().required("Select a valid price").positive(),
		gst_rate: Yup.string().nullable(),
		weight: Yup.number().required().positive(),
		length: Yup.number().required().positive(),
		width: Yup.number().required().positive(),
		height: Yup.number().required().positive(),
		display_name: Yup.string(),
		attribute_value: Yup.string(),
		attribute: Yup.string(),
		single_qty: Yup.number()
			.transform((value, originalValue) =>
				originalValue === "" ? null : value
			)
			.nullable(),
		sub_category: Yup.string().default(lastSubCategory?.pk || ""),
		quantity: Yup.number().positive().nullable(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		const fetchBrands = async () => {
			try {
				const response = await axiosInstance.get(`shops/admin/brands/`);
				setBrands(response.data.data.brands);
			} catch (err) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};
		fetchBrands();
	}, []);

	useEffect(() => {
		if (!enableAttributeQty) return;
		const fetchAttributes = async () => {
			try {
				const response = await axiosInstance.get(`shops/admin/attributes/`);
				setAttributes(response.data.results);
			} catch (err) {
				setError(err.message);
			}
		};
		fetchAttributes();
	}, [enableAttributeQty]);

	const handleToggle = async () => {
		setToggled((prev) => !prev);
		if (!toggled) {
			try {
				const response = await axiosInstance.get("shops/admin/attributes/");
				setAttributeList(response.data.results);
			} catch (err) {
				setError(err.message);
			}
		}
	};

	const handleAttributeChange = async (event) => {
		const attributeId = event.target.value;
		setSubOptions([]);
		setError(null);
		if (!attributeId) return;

		try {
			const response = await axiosInstance.get(`shops/admin/attributes/`);
			const selectedAttribute = response.data.results.find(
				(attr) => attr.id === attributeId
			);
			if (selectedAttribute && selectedAttribute.value) {
				const options = selectedAttribute.value
					.split(",")
					.map((option, i) => option.trim());
				setSubOptions(options);
				setModal(true);
			} else {
				setSubOptions([]);
			}
		} catch (err) {
			setError(err.message);
		}
	};

	const handleCheckboxChange = (option) => {
		setSelectedOptions((prev) =>
			prev.includes(option)
				? prev.filter((item) => item !== option)
				: [...prev, option]
		);
	};

	const handleInputChange = (option, value) => {
		setQuantityValues((prev) => ({ ...prev, [option]: value }));
	};

	const handleSkuChange = (e) => {
		setSku(e.target.value);
	};

	const onSubmit = async (data) => {
		try {
			setLoading(true);

			if (toggled) {
				data.attributes = selectedOptions.map((option, i) => ({
					option,
					quantity: quantityValues[option] || 0,
					sku,
				}));
			}
			// set images from cache
			data.featured_image_url = localStorage.getItem("coverImageUrl");
			const images = JSON.parse(localStorage.getItem("productImages")) || [];
			data.images = images.join(",");

			await axiosInstance
				.post("shops/products/create/", data)
				.then((response) => {
					console.log("response=======", response);
					if (response.status == 200 && response?.data?.StatusCode == 6000) {
						// Set product details before using them
						setProductDetails(response.data.data);

						setSuccess((success) => !success); // Correct way to toggle state
						console.log(success, " will be updated in the next render");
					} else {
						toast(
							<ToastSucessMessage
								icon={cross}
								message="failed to create product"
							/>
						);
					}
				});
		} catch (error) {
			alert(error.response?.data?.message || "Error creating the product.");
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Container>
				<CopyInputSection>
					<CustomCheckbox
						checked={isChecked}
						onChange={() => setIsChecked(!isChecked)}
						themeColor="#D4A017" // Gold color
					/>
					<Content>
						<ContentLabel>Copy input to all details</ContentLabel>
						<ContantDescription>
							if you want to change spesfic field for perticular products like
							color, fabric, etc. you can change it by selecting that product
						</ContantDescription>
					</Content>
				</CopyInputSection>

				<FormSection onSubmit={handleSubmit(onSubmit)}>
					<BasicInput>
						<InputWrapper>
							<InputLabel>Product Name</InputLabel>
							<TextInput
								{...register("name")}
								type="text"
								name="name"
								placeholder="Enter Product Name"
							/>
							<Error>{errors.name?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Product Description</InputLabel>
							<TextArea
								{...register("description")}
								name="description"
								placeholder="Enter Product Description"
							/>
							<Error>{errors.description?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Brand</InputLabel>
							<BrandSelect name="brand" {...register("brand")}>
								<option value="">-- Select a Brand --</option>
								{brands?.map((brand, i) => (
									<option key={brand.slug || i} value={brand.slug}>
										{brand.name}
									</option>
								))}
							</BrandSelect>
							<Error>{errors.brand?.message}</Error>
						</InputWrapper>
					</BasicInput>
					<SectionBreaker>
						<SectionTitle>General</SectionTitle>
						<Line>
							<span></span>
						</Line>
					</SectionBreaker>
					<GeneralInput>
						<InputWrapper>
							<InputLabel>Product SKU</InputLabel>
							<TextInput
								{...register("product_code")}
								type="text"
								name="product_code"
								placeholder="Enter Product Code"
							/>
							<Error>{errors.product_code?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>HSN Code</InputLabel>
							<TextInput
								{...register("hsn_code")}
								type="text"
								name="hsn_code"
								placeholder="Enter Product Code"
							/>
							<Error>{errors.hsn_code?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Return In</InputLabel>
							<BrandSelect name="return" {...register("return")}>
								<option value="">-- Select a Return--</option>
								<option value="7">7 Days</option>
								<option value="14">14 Days</option>
								<option value="30">30 Days</option>
							</BrandSelect>
							<Error>{errors.return?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Warranty</InputLabel>
							<BrandSelect name="warranty" {...register("warranty")}>
								<option value="">-- Select Warranty--</option>
								<option value="0">None</option>
								<option value="180">6 Months</option>
								<option value="365">1 Year</option>
							</BrandSelect>
							<Error>{errors.warranty?.message}</Error>
						</InputWrapper>
						<InputWrapper>
							<InputLabel>Actual Price</InputLabel>
							<TextInput
								{...register("actual_cost")}
								type="number"
								name="actual_cost"
								step=".01"
								placeholder="₹0000"
							/>
							<Error>{errors.actual_cost?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Display Price</InputLabel>
							<TextInput
								{...register("display_cost")}
								type="number"
								name="display_cost"
								step=".01"
								placeholder="₹0000"
							/>
							<Error>{errors.display_cost?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>GST</InputLabel>
							<TextInput
								{...register("gst_rate")}
								type="text"
								name="gst_rate"
								placeholder="Enter GST amount"
							/>
							<Error>{errors.gst?.message}</Error>
						</InputWrapper>{" "}
					</GeneralInput>
					<SectionBreaker>
						<SectionTitle>Shipping</SectionTitle>
						<Line>
							<span></span>
						</Line>
					</SectionBreaker>
					<ShippingInput>
						<InputWrapper>
							<InputLabel>Weight</InputLabel>
							<TextInput
								{...register("weight")}
								type="number"
								name="weight"
								step=".01"
								placeholder="00"
							/>
							<Error>{errors.weight?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Length (cm)</InputLabel>
							<TextInput
								{...register("length")}
								type="number"
								name="length"
								step=".01"
								placeholder="00"
							/>
							<Error>{errors.length?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Width (cm)</InputLabel>
							<TextInput
								{...register("width")}
								type="number"
								name="width"
								placeholder="00"
								step=".01"
							/>
							<Error>{errors.width?.message}</Error>
						</InputWrapper>{" "}
						<InputWrapper>
							<InputLabel>Height (cm)</InputLabel>
							<TextInput
								{...register("height")}
								type="number"
								name="height"
								placeholder="00"
								step=".01"
							/>
							<Error>{errors.height?.message}</Error>
						</InputWrapper>{" "}
					</ShippingInput>
					<SectionBreaker>
						<SectionTitle>Product quantity</SectionTitle>
						<ToggleContainer>
							<button
								className={`toggle_btn ${toggled ? "toggled" : ""}`}
								onClick={(e) => {
									e.preventDefault();
									handleToggle();
								}}
							>
								<div className="thumb"></div>
							</button>
						</ToggleContainer>
						<span
							style={{
								width: "175px",
								marginLeft: "16px",
								marginRight: "10px",
								color: "##818181",
								fontSize: "14px",
							}}
						>
							Have attributes
						</span>

						<Line>
							<span></span>
						</Line>
					</SectionBreaker>
					<InputWrapper>
						<InputLabel hidden={toggled}>Quantity</InputLabel>
						<TextInput
							hidden={toggled}
							{...register("single_qty")}
							type="numeric"
							name="single_qty"
							placeholder="Enter Qty"
						/>
						<Error>{errors.single_qty?.message}</Error>
					</InputWrapper>
					{toggled && attributeList && (
						<AttributeInput>
							<InputWrapper>
								<InputLabel>Display Name</InputLabel>
								<TextInput
									{...register("display_name")}
									type="text"
									name="display_name"
									placeholder="Enter Name"
								/>
								<Error>{errors.display_name?.message}</Error>
							</InputWrapper>{" "}
							<InputWrapper className="attributes">
								<InputLabel>Select Attribute</InputLabel>
								<BrandSelect
									name="attribute"
									{...register("attribute")}
									onChange={handleAttributeChange}
									onClick={() => {
										setModal(false);
									}}
								>
									<option value="">-- Select an Attribute --</option>
									{attributeList?.map((attribute, i) => (
										<option key={attribute.id || i} value={attribute.id}>
											{attribute.title}
										</option>
									))}
								</BrandSelect>
								<Error>{errors.attribute?.message}</Error>
								{subOptions.length > 0 && modal && (
									<SizeBody>
										{subOptions.map((option, index) => (
											<BodyItem key={index} className="checkbox-container">
												<input
													type="checkbox"
													id={`option-${index}`}
													name="subOption"
													value={option}
													checked={selectedOptions.includes(option)}
													onChange={() => handleCheckboxChange(option)}
												/>
												<Option
													htmlFor={`option-${index}`}
													className="checkbox-label"
												>
													{option}
												</Option>
											</BodyItem>
										))}
									</SizeBody>
								)}
							</InputWrapper>
							{selectedOptions.map((option, index) => (
								<InputWrapper key={option.id || index}>
									<InputLabel>Values</InputLabel>
									<Sizevalues>
										<InputLabel className="input-values">{option}</InputLabel>
										<InputField
											{...register("attribute_quantity")}
											type="text"
											placeholder="Enter QTY"
											value={quantityValues[option] || ""}
											onChange={(e) =>
												handleInputChange(option, e.target.value)
											}
										/>
										<InputField
											{...register("attribute_sku")}
											type="text"
											placeholder="Enter SKU"
											value={sku}
											onChange={handleSkuChange}
										/>
									</Sizevalues>
									<Error>{errors[option]}</Error>
								</InputWrapper>
							))}
						</AttributeInput>
					)}

					<ButtonContainer>
						<CancelButton>Cancel</CancelButton>
						<SubmitButton type="submit">Submit</SubmitButton>
					</ButtonContainer>
				</FormSection>
			</Container>
			{success && (
				<SuccessModal
					success={success}
					setSuccess={setSuccess}
					onClose={() => setSuccess((success) => !success)}
					message="Product Created Successfully!"
				/>
			)}
		</>
	);
}

export default InputSection;
const Container = styled.div`
	margin-top: 20px;
`;
const CopyInputSection = styled.div`
	padding: 16px;
	border: 1px solid ${COLORS.THEME_COLOR};
	background-color: #fffff0;
	border-radius: 6px;
	display: flex;
	justify-content: flex-start;
`;
const RadioButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	min-width: 20px;
	height: 20px;
	border: 2px solid ${COLORS.THEME_COLOR};
	border-radius: 5px;
`;
const Tick = styled(DoneOutlinedIcon)`
	font-size: 14px !important;
	color: ${COLORS.THEME_COLOR};
`;
const Content = styled.div`
	margin-left: 10px;
`;
const ContentLabel = styled.p`
	font-size: 15px;
	color: ${COLORS.PRIMARY_TEXT};
	margin-bottom: 6px;
`;
const ContantDescription = styled.p`
	font-size: 14px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const FormSection = styled.form`
	display: block;
	margin-top: 50px;
`;
const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	position: relative;
	&.attributes {
		position: relative;
	}
`;

const InputLabel = styled.label`
	font-size: 16px;
	color: ${COLORS.SECONDARY_TEXT};
	&.input-values {
		border: 2px solid #eeeeee;
		width: 130px;
		height: 66px;
		background: #fff;
		padding: 18px 23px;
		border-radius: 10px;
	}
`;

const TextInput = styled.input`
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 60%;
	border: 1px solid #eeeeee;
`;

const TextArea = styled.textarea`
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	height: 100px;
	width: 60%;
	border: 1px solid #eeeeee;
`;

const BrandSelect = styled.select`
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 60%;
	border: 1px solid #eeeeee;
`;
const Error = styled.p`
	font-size: 12px;
	color: red;
	bottom: -20px;
	right: 0;
	position: absolute;
`;

const BasicInput = styled.div``;
const GeneralInput = styled.div``;
const SectionBreaker = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 30px;
`;
const SectionTitle = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 16px;
	width: 230px;
	/* margin-right: 20px; */
`;
const Line = styled.span`
	width: 100%;
	display: flex;
	align-items: center;
	span {
		display: block;
		flex: 1;
		border-bottom: 1px solid #f0f0f0;
		background: #ebebeb;
		height: 2px;
	}
`;
const ToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	& button.toggle_btn {
		background-color: #d1d9e2;
		border: none;
		border-radius: 99px;
		width: 50px;
		height: 28px;
		transition: background-color 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		position: relative;
	}

	& button.toggled {
		background: linear-gradient(to right, #fef7a3 0%, #feda00 100%);
	}

	& button:hover {
		border-color: #feda00;
	}

	& div.thumb {
		height: 20px;
		width: 20px;
		background-color: #fff;
		border-radius: 99px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: transform 0.3s ease;
		left: 3px;
	}

	& button.toggled div.thumb {
		transform: translateY(-50%) translateX(22px); /* Move thumb to the right when toggled */
	}
`;
const ShippingInput = styled.div``;
const AttributeInput = styled.div``;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
	position: absolute;
	width: 100%;
	bottom: 0;
	right: 0;
	border-top: 1px solid ${COLORS.SECONDARY};
	padding-top: 20px;
`;
const CancelButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 130px;
	height: 40px;
	color: ${COLORS.PRIMARY_TEXT};
	background: ${COLORS.THEME_GRADIENT};
	border: none;
	cursor: pointer;
	border-radius: 5px;
`;

const SizeBody = styled.div`
	position: absolute;
	margin-top: 20px;
	width: 200px;
	background-color: #fff;
	border-radius: 8px;
	padding: 10px;
	right: 10px;
	z-index: 1;
	border: 2px solid #e5e2e2;
`;

const BodyItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;

	input {
		display: none;
	}

	input:checked + label::before {
		content: "✔";
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 14px;
		font-weight: bold;
		background: linear-gradient(to right, #fef7a3, #feda00);
	}
`;

const Option = styled.label`
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #747474;

	&::before {
		content: "";
		display: inline-block;
		width: 24px;
		height: 24px;
		background: linear-gradient(to right, #fef7a3, #ffcc33);
		border-radius: 8px;
		margin-right: 10px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		transition: background-color 0.2s ease;
	}
`;
const InputField = styled.input`
	border: 2px solid #eeeeee;
	width: 130px;
	height: 66px;
	background: #fff;
	padding: 18px 23px;
	border-radius: 10px;
`;
const Sizevalues = styled.div`
	width: 60%;
	display: flex;
	justify-content: space-between;
`;

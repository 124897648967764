import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VideoModal from "../general/VideoModal";
import Banner from "../includes/Dashboard/Banner";
import OrderUpdates from "../includes/Dashboard/OrderUpdates";
import thumanil from "../../assets/images/thumnail.png";
import play from "../../assets/images/video_play.svg";
import { COLORS } from "../../constants";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import useGetApi from "../../hooks/useGetApi";

function DashBoard() {
	const [isVideoModal, setVideoModal] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(updateActiveMenu("dashboard"));
	}, []);

	const handleClose = () => {
		setVideoModal(false);
	};
	const { data } = useGetApi("shops/admin/dashboard/");
	console.log(data?.data, "piuytr");

	return (
		<Container>
			<VideoModal
				isOpen={isVideoModal}
				videoId={"https://www.youtube.com/embed/EnhS3matIoU"}
				onClose={handleClose}
			/>
			{/* <Banner /> */}
			<OrderUpdates datas={data?.data} />
			{/* <VideoContainer>
        <ThumnailCover>
          <PlayButton src={play} onClick={() => setVideoModal(true)} />
          <Thumnail src={thumanil} />
        </ThumnailCover>
        <VideoTitle>Watch your video</VideoTitle>
        <VideoLabel>
          <Time /> 2h3m
        </VideoLabel>
      </VideoContainer> */}
		</Container>
	);
}

export default DashBoard;
const Container = styled.div``;
const VideoContainer = styled.div`
	margin-top: 30px;
	background-color: #fff;
	padding: 16px;
	width: 100%;
	max-width: 500px;
	border-radius: 5px;
`;
const ThumnailCover = styled.div`
	position: relative;
`;
const Thumnail = styled.img`
	display: block;
	width: 100%;
`;
const PlayButton = styled.img`
	width: 60px;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const VideoTitle = styled.p`
	color: ${COLORS.PRIMARY_TEXT};
	margin-top: 10px;
`;
const VideoLabel = styled.p`
	color: ${COLORS.SECONDARY_TEXT};
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const Time = styled(QueryBuilderOutlinedIcon)`
	color: ${COLORS.SECONDARY_TEXT};
	font-size: 18px !important;
	margin-right: 5px;
`;

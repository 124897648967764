import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import InputSection from "./InputSection";
import upload_img from "../../../assets/images/upload_img.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import UploadImageModal from "./UploadImageModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function ProductInputSection() {
	const [isUploadCoverModel, setUploadCoverModal] = useState(false);
	const [coverImage, setCoverImage] = useState(
		localStorage.getItem("coverImageUrl")
	);

	const [isImageModal, setImageModal] = useState(false);
	const [productImages, setProductImages] = useState([]);

	//functionsss------------------------------------------>
	const handleCoverModal = () => {
		setUploadCoverModal((prev) => !prev);
	};

	const handleCoverSubmit = (data) => {
		setCoverImage(data);
		handleCoverModal();
	};
	const cleanCoverImage = () => {
		setCoverImage(null);
	};

	const handleImageModal = () => {
		setImageModal((prev) => !prev);
	};
	console.log(isImageModal, "isImageModal");

	const handleImagesSubmit = (data) => {
		setProductImages([...productImages, data]);
		handleImageModal();
	};
	const cleanProductImage = (url) => {
		setProductImages(() => productImages.filter((item) => item !== url));
	};
	useEffect(() => {
		localStorage.setItem("productImages", JSON.stringify(productImages));
	}, [productImages]);

	return (
		<Container>
			<LeftSection>
				<Title>Product Details</Title>
				<InputSection />
			</LeftSection>
			<RightSection>
				<Title>Product Cover Photo</Title>
				{coverImage ? (
					<CoverImage>
						<CloseIcon onClick={cleanCoverImage}>
							<Close />
						</CloseIcon>
						<ImagePreview src={coverImage} alt="Uploaded image" />
					</CoverImage>
				) : (
					<>
						<UploadImageModal
							title="Product Product Photo"
							isOpen={isImageModal}
							close={handleImageModal}
							handleSubmit={handleImagesSubmit}
							imageType="cover-image"
						/>
						<ProductCoverImage onClick={handleCoverModal}>
							<UpLoadImageIcon>
								<img src={coverImage || upload_img} alt="Upload Image" />
							</UpLoadImageIcon>
							<Label>
								Cover Photo is the first photo of product while user visit
								product page
							</Label>
							<ButtonAddPhoto>Add Photo</ButtonAddPhoto>
						</ProductCoverImage>
					</>
				)}
				<ProductImages>
					<TopSection>
						<Title>Product Images</Title>
						<p>Maximum 9 photos</p>
					</TopSection>
					<ImageContainer>
						{productImages.length > 0 &&
							productImages.map((imageUrl, i) => (
								<ProductImage key={i}>
									<CloseIcon onClick={() => cleanProductImage(imageUrl)}>
										<Close />
									</CloseIcon>
									<img src={imageUrl} alt="Uploaded image" />
								</ProductImage>
							))}

						<AddImageButton onClick={handleImageModal}>
							<AddIcon />
							<p>Add Image</p>
						</AddImageButton>
						{isImageModal && (
							<>
								<UploadImageModal
									title="Product Product Photo"
									isOpen={isImageModal}
									close={handleImageModal}
									handleSubmit={handleImagesSubmit}
									imageType="product-images"
								/>
							</>
						)}
					</ImageContainer>
				</ProductImages>
			</RightSection>
		</Container>
	);
}

export default ProductInputSection;
const Container = styled.div`
	margin-top: 20px;
	position: relative;
	display: grid;
	grid-template-columns: 7fr 3fr;
	padding-bottom: 90px;
`;
const LeftSection = styled.div`
	min-height: 100vh;
	border-right: 1px solid #f0f0f0;
	flex: 1;
	padding: 10px 20px 0 0;
`;
const RightSection = styled.div`
	flex: 1;
	/* max-width: 320px; */
	padding: 10px 0 0 20px;
	min-width: 345px;
`;
const Title = styled.h3`
	color: ${COLORS.PRIMARY_TEXT};
	font-size: 16px;
	font-family: "dm_sans_reg";
`;
const ProductCoverImage = styled.div`
	border: 3px dashed #d4d4d4;
	padding: 20px;
	margin-top: 20px;
	border-radius: 10px;
	cursor: pointer;
`;
const UpLoadImageIcon = styled.span`
	display: block;
	width: 60px;
	margin: 0 auto;
	img {
		width: 100%;
		display: block;
	}
`;
const Label = styled.p`
	width: 90%;
	margin: 0 auto;
	font-size: 13px;
	text-align: center;
	margin-top: 14px;

	color: ${COLORS.SECONDARY_TEXT};
`;
const ButtonAddPhoto = styled.span`
	color: #eab600;
	font-size: 16px;
	display: block;
	text-align: center;
	width: 100px;
	margin: 0 auto;
	margin-top: 20px;
	border-bottom: 2px solid #eab600;
	cursor: pointer;
`;
const ProductImages = styled.div`
	margin-top: 50px;
`;
const TopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	p {
		color: #eab600;
		font-size: 14px;
	}
`;
const ImageContainer = styled.div`
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
`;
const AddImageButton = styled.span`
	height: 100px;
	width: 100px;
	border: 2px dashed #eab600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	flex-wrap: wrap;
	cursor: pointer;
	p {
		color: #eab600;
		font-size: 14px;
	}
`;
const AddIcon = styled(AddOutlinedIcon)`
	display: block;
	font-size: 60px !important;
	color: #eab600;
`;
const CoverImage = styled.div`
	margin-top: 20px;
	position: relative;
`;
const ImagePreview = styled.img`
	width: 100%;
	display: block;
	max-height: 300px;
	object-fit: cover;
	border-radius: 5px;
	overflow: hidden;
`;
const CloseIcon = styled.span`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -10px;
	right: -10px;
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: #fff;
	border-radius: 50%;
	border: 1px solid rgba(238, 238, 238, 1);
	cursor: pointer;
`;
const Close = styled(CloseOutlinedIcon)`
	font-size: 14px !important;
	color: rgba(116, 116, 116, 1);
`;
const ProductImage = styled.span`
	width: 100px;
	height: 100px;
	display: block;
	position: relative;
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		border-radius: 5px;
	}
`;

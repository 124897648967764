import { useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, COLORS } from "../../../constants";
import { getToken, stringFormatDate } from "../../utils/HelperFunctions";
import CancelOrderModal from "./modals/CancelOrderModal";
import ConfirmOrderModal from "./modals/ConfirmOrderModal";
import OrderActionsButton from "./OrderActionsButton";
import { ToastContainer, toast } from "react-toastify";
import OrderStatusButton from "./OrderStatusButton";
import ToastSucessMessage from "../../general/Auth/ToastSucessMessage";
import { Avatar } from "@mui/material";
import axiosInstance from "../../../api";
import useGetApi from "../../../hooks/useGetApi";
import tick from "../../../assets/images/sucess.svg";

const OrderDetailsTable = ({
	columns,
	data,
	fetchData,
	loading,
	pageCount,
	totalPages,
	setPage,
	currentPage,
	OrderData,
	orderDataProducts,
	setOrderDataProducts,
}) => {
	const { orderData } = useGetApi("/orders/");

	const totalOrders = OrderData?.count;
	const totalEntries = OrderData?.results;

	const [selectedOrder, setSelectedOrder] = useState({});
	const [isConfirmModal, setConfirmModal] = useState(false);
	const [isCancelModal, setCancelModal] = useState(false);
	const handleAddStockModal = () => {
		setAddStockModal(!addStockModal);
	};

	function stringAvatar(name) {
		return {
			sx: {
				bgcolor: "#FFF199",
			},
			children: `${name
				?.split(" ")
				.map((n) => n[0])
				.slice(0, 2)
				.join("")}`,
		};
	}
	const handleConfirmModal = () => {
		setConfirmModal(!isConfirmModal);
	};
	const handleCancelModal = () => {
		setCancelModal(!isCancelModal);
	};

	const handleConfirmClick = () => {
		setConfirmModal(false);
		toast(<ToastSucessMessage icon={tick} />);
	};

	return (
		<TableWrapper>
			<ToastContainer
				position="bottom-center"
				autoClose={3000}
				limit={1}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<ConfirmOrderModal
				selectedOrder={selectedOrder}
				handleModal={handleConfirmModal}
				isModal={isConfirmModal}
				handleConfirmClick={handleConfirmClick}
			/>

			<TableContainer>
				<thead>
					<TableRow>
						<TableHead>Sl No.</TableHead>
						<TableHead>Order ID</TableHead>
						<TableHead>Product Details</TableHead>
						<TableHead>Customer Details</TableHead>
						<TableHead>Shipping Address</TableHead>
						<TableHead>Date</TableHead>
						<TableHead>Status</TableHead>
						<TableHead>Actions</TableHead>
					</TableRow>
				</thead>
				<TableBody>
					{data?.map((data, key) => {
						return (
							<>
								<TableRow key={key}>
									<TableCell className="auto_id">
										{data.auto_id < 10 ? "0" + data.auto_id : data.auto_id}
									</TableCell>
									<TableCell
										className="truncated"
										style={{
											maxWidth: "200px",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
										title={data.order_id}
									>
										{data.order_id.length > 4
											? `...${data.order_id.slice(-2)}`
											: data.order_id}
									</TableCell>

									<TableCell>
										<ProductTitle>{data.product_name}</ProductTitle>
										<ProductDescription>
											{data.product_description}
										</ProductDescription>
									</TableCell>
									<TableCell>
										<div className="customer_details">
											<Avatar
												{...stringAvatar(data.customer_data.customer_name)}
											/>
											<div className="customer_data">
												<CustomerName>
													{data.customer_data.customer_name}
												</CustomerName>
												<CustomerPhone>
													{" "}
													{data.customer_data.customer_phone}
												</CustomerPhone>
											</div>
										</div>
									</TableCell>
									<TableCell className="address">
										{data.shipping_address}
									</TableCell>
									<TableCell className="date_order">{data.date}</TableCell>
									<TableCell>
										<OrderStatusButton status={data.status} />
									</TableCell>
									<TableCell>
										<TableButton>
											<OrderActionsButton
												handleCancelModal={handleCancelModal}
												handleConfirmModal={handleConfirmModal}
												data={data}
												selectedOrder={selectedOrder}
												setSelectedOrder={setSelectedOrder}
												handleAddStockModal={handleAddStockModal}
											/>
										</TableButton>
									</TableCell>
								</TableRow>
								{/* <CancelOrderModal
                                    selectedOrder={selectedOrder}
                                    handleModal={handleCancelModal}
                                    isModal={isCancelModal}
                                    data={data}
                                    handleConfirmClick={handleConfirmClick}
                                /> */}
								<CancelOrderModal
									selectedOrder={selectedOrder}
									handleModal={handleCancelModal}
									isModal={isCancelModal}
									data={data}
									// onCancelConfirm={handleCancelConfirmClick} // Pass function here
								/>
							</>
						);
					})}
				</TableBody>
			</TableContainer>
		</TableWrapper>
	);
};

export default OrderDetailsTable;
const TableWrapper = styled.div`
	overflow-x: scroll;
	background-color: ${COLORS.PRIMARY};
	padding: 10px;

	height: calc(100vh - 328px);
	width: 100%;
	padding-bottom: 60px;
`;

const TableContainer = styled.table`
	border-collapse: collapse;
	width: 100%;
	min-width: 1150px;
`;
const TableBody = styled.tbody`
	transition: 0.3s;
	tr {
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			background-color: ${COLORS.SECONDARY};
		}
	}
`;

const TableHead = styled.th`
	font-weight: bold;
	text-align: left;
	padding: 10px 15px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid ${COLORS.SECONDARY};
`;

const TableCell = styled.td`
	text-align: left;
	vertical-align: middle;
	padding: 15px;
	font-size: 14px;
	max-width: 250px;
	font-family: "dm_sans_reg";

	&.auto_id {
		font-weight: 900;
	}
	&.truncated {
		font-weight: 900;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100px;
		position: relative;
	}
	&.truncated:hover {
		white-space: normal;
		overflow: visible;
		text-overflow: clip;
	}
	& div.customer_details {
		display: flex;
		align-items: center;
		gap: 10px;

		&.customer_data {
			display: flex;
			flex-direction: column;
		}
	}
	&.address {
		color: #0a0a0a;
		font-weight: 400;
	}
	&.date_order {
		font-weight: 600;
	}
`;

const TableButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	overflow: visible;
	&:hover {
	}
`;

const ProductTitle = styled.p`
	font-size: 18px;
	text-transform: capitalize;
	font-weight: 900;
`;
const ProductDescription = styled.p`
	font-size: 14px;
	color: ${COLORS.SECONDARY_TEXT};
`;
const CustomerName = styled.p`
	font-size: 17px;
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 900;
`;
const CustomerPhone = styled.p`
	font-size: 13px;
	color: ${COLORS.SECONDARY_TEXT};
`;

import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import trash from "../../../assets/images/trash.svg";
import edit from "../../../assets/images/edit.svg";
import axios from "axios";
import { getToken } from "../../utils/HelperFunctions";
import { API_BASE_URL } from "../../../constants";

const AttributeTable = ({
    attributes,
    handleModal,
    isEditModal,
    setEditModal,
    handleEdit,
}) => {
    const token = getToken();

    const [localAttributes, setLocalAttributes] = useState(attributes);

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${API_BASE_URL}shops/admin/attributes/${id}/`,

                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setLocalAttributes((prevAttributes) =>
                prevAttributes.filter((attr) => attr.id !== id)
            );

            console.log("Attribute deleted successfully:", response.data);
        } catch (error) {
            console.error("Error deleting attribute:", error.response?.data);
        }
    };

    return (
        <Table>
            <thead>
                <TableRow>
                    <TableHeader className="slno">Sl No.</TableHeader>
                    <TableHeader>Title</TableHeader>
                    <TableHeader>Value</TableHeader>
                    <TableHeader>Action</TableHeader>
                </TableRow>
            </thead>
            <tbody>
                {localAttributes?.map((attribute, index) => (
                    <TableRow key={attribute.id}>
                        <TableData className="slno">{index + 1}</TableData>
                        <TableData>{attribute.title}</TableData>

                        <TableData>{attribute.value}</TableData>
                        <TableData>
                            <ActionButtons>
                                <EditButton
                                    onClick={() => handleEdit(attribute)}
                                >
                                    <img src={edit} alt="Edit" />
                                </EditButton>
                                <DeleteButton
                                    onClick={() => handleDelete(attribute.id)}
                                >
                                    <img src={trash} alt="Delete" />
                                </DeleteButton>
                            </ActionButtons>
                        </TableData>
                    </TableRow>
                ))}
            </tbody>
        </Table>
    );
};

export default AttributeTable;

// Styled components
const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    min-width: 600px;
    height: 100%;
    overflow-x: scroll;
`;

const TableRow = styled.tr`
    padding: 10px 0;
    border-bottom: 1px solid ${COLORS.SECONDARY};
    .slno {
        max-width: 25px;
    }
`;

const TableHeader = styled.th`
    color: ${COLORS.SECONDARY_TEXT};
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    text-align: left;
    :last-child {
        text-align: end;
    }
`;

const TableData = styled.td`
    color: ${COLORS.PRIMARY_TEXT};
    font-size: 14px;
    padding: 20px 12px;
    text-align: left;
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ActionButton = styled.button`
    background-color: transparent;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    padding: 5px;

    &:hover {
        color: #333;
    }
`;

const EditButton = styled.span`
    width: 20px;
    display: block;
    margin-right: 20px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

const DeleteButton = styled.span`
    width: 20px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;

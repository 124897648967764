import React, { useState } from "react";
import styled from "styled-components";
import AddProductDetails from "../../includes/Products/AddProductDetails";
import SelectCategory from "../../includes/Products/SelectCategory";
import InputSection from "../../includes/Products/InputSection";

function AddProduct() {
    const [currentPage, setCurrentPage] = useState("add-category");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    return (
        <Container>
            {currentPage === "add-category" ? (
                <CategorySelectorCover>
                    <SelectCategory
                        setCurrentPage={setCurrentPage}
                        setSelectedSubCategory={setSelectedSubCategory}
                        selectedSubCategory={selectedSubCategory}
                    />
                </CategorySelectorCover>
            ) : currentPage === "add-product" ? (
                <ProductSelectorCover>
                    <AddProductDetails
                        setCurrentPage={setCurrentPage}
                        subCategory={selectedSubCategory}
                    />
                </ProductSelectorCover>
            ) : null}
        </Container>
    );
}

export default AddProduct;
const Container = styled.div`
    &::-webkit-scrollbar {
        opacity: 0;
    }
`;
const CategorySelectorCover = styled.div``;
const ProductSelectorCover = styled.div`
    &::-webkit-scrollbar {
        display: none !important;
    }
`;

import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { API_BASE_URL, COLORS } from "../../../../constants";
import { getToken } from "../../../utils/HelperFunctions";
import axiosInstance from "../../../../api";
import { toast } from "react-toastify";

const CancelOrderModal = ({ selectedOrder, handleModal, isModal, data }) => {
    const handleCancelClick = () => {
        handleModal();
    };
    const token = getToken();
    const [orderId, setOrderId] = useState("");
    const handleOnchange = (e) => {
        setOrderId(e.target.value);
        console.log(e.target.value);
    };
    const handleCancelConfirmClick = async () => {
        try {
            const token = getToken();
            const orderId = data?.pk;

            if (!orderId) {
                console.error("Order ID is missing!");
                toast.error("Order ID not found. Unable to cancel order.");
                return;
            }

            console.log("Order ID to cancel:", orderId);

            const response = await axiosInstance.post(
                `${API_BASE_URL}orders/order/cancel/${orderId}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("Response:", response);

            if (response.status === 200) {
                toast.success("Order canceled successfully!");

                setOrderDataProducts((prevData) =>
                    prevData.filter((item) => item.pk !== orderId)
                );

                handleCancelModal();
            } else {
                throw new Error(
                    `Failed to cancel order with status: ${response.status}`
                );
            }
        } catch (error) {
            console.error(
                "Error canceling order:",
                error.response || error.message
            );
            toast.error("Failed to cancel order. Please try again.");
        }
    };

    // const handleCancelConfirmClick = async (order) => {
    //     try {
    //         const token = getToken();
    //         const orderId = order.pk;

    //         console.log(orderId, "Order ID to cancel");

    //         const response = await axiosInstance.post(
    //             `${API_BASE_URL}orders/order/cancel/${orderId}/`,
    //             {},
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     "Content-Type": "application/json",
    //                 },
    //             }
    //         );

    //         if (response.status === 200) {
    //             toast.success("Order canceled successfully!");

    //             setOrderDataProducts((prevData) =>
    //                 prevData.filter((item) => item.pk !== orderId)
    //             );
    //         } else {
    //             throw new Error(
    //                 `Failed to cancel order with status: ${response.status}`
    //             );
    //         }

    //         console.log("API response:", response.data);
    //     } catch (error) {
    //         console.error("Error canceling order:", error);
    //         toast.error("Failed to cancel order. Please try again.");
    //     }
    // };

    return (
        <ModalWrapper isModal={isModal}>
            <ModalContent>
                <ModalTopSection>
                    <Title>Cancel Order</Title>
                    <CloseButton onClick={handleModal}>
                        {" "}
                        <CloseIcon />
                    </CloseButton>
                </ModalTopSection>
                <Description>
                    Please ensure your order ID to confirm the order, we will
                    sent to the shipping teams
                </Description>
                <InputSection>
                    <Label htmlFor="orderId">Order ID</Label>
                    <Input
                        onChange={handleOnchange}
                        type="text"
                        id="orderId"
                        placeholder="Enter Order ID"
                    />
                </InputSection>
                <ButtonContainer>
                    <CancelButton
                        onClick={() => onCancelConfirm(selectedOrder)}
                    >
                        Cancel
                    </CancelButton>
                    <ConfirmButton
                        onClick={() => handleCancelConfirmClick(selectedOrder)}
                    >
                        Cancel Order
                    </ConfirmButton>
                </ButtonContainer>
            </ModalContent>
        </ModalWrapper>
    );
};

export default CancelOrderModal;
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00011f33;
    backdrop-filter: blur(3px);
    display: ${(props) => (props.isModal ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    z-index: 99;
`;

const ModalContent = styled.div`
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
    width: 90%;
    max-width: 600px;
`;

const ModalTopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${COLORS.SECONDARY};
    margin-bottom: 30px;
`;

const Title = styled.h2`
    font-size: 20px;
    color: ${COLORS.PRIMARY_TEXT};
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: #555;
    cursor: pointer;
    font-size: 1.25rem;

    &:hover {
        color: #000;
    }
`;
const Description = styled.p`
    color: ${COLORS.SECONDARY_TEXT};
`;
const InputSection = styled.div`
    margin-top: 1.5rem;
`;

const Label = styled.label`
    font-size: 15px;
    font-weight: bold;
    color: ${COLORS.SECONDARY_TEXT};
`;

const Input = styled.input`
    display: block;
    width: 100%;
    border: none;
    border: 2px solid #eab600;
    margin-top: 0.5rem;
    padding: 0.25rem 0;
    height: 50px;
    padding: 0 10px;
    border-radius: 6px;
    font-size: 18px;

    &:focus {
        outline: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
`;

const CancelButton = styled.span`
    display: block;
    color: ${COLORS.SECONDARY_TEXT};
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-right: 1rem;

    &:hover {
        opacity: 0.8;
    }
`;

const ConfirmButton = styled.button`
    background-color: #c33025;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

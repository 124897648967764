import React from "react";
import Tick from "../../../assets/images/yellow-tick.svg";
import styled from "styled-components";
import { COLORS } from "../../../constants";

function SuccessModal({ success, setSuccess }) {
    console.log("Recevied to modal function ", success);
    if (!success) return null;
    return (
        <>
            <ModalBackdrop
                onClick={() => {
                    setSuccess(false);
                }}
            >
                <ModalContent>
                    <ImgContainer>
                        <img src={Tick} alt="image" />
                    </ImgContainer>
                    <Title>Product Successfully Added !</Title>
                    <Paragraph>
                        You have successfully product added. Click continue to
                        go to the dashboard{" "}
                    </Paragraph>
                    <Button>Continue</Button>
                </ModalContent>
            </ModalBackdrop>
        </>
    );
}

export default SuccessModal;
const ModalBackdrop = styled.div`
    background-color: rgba(0, 1, 31, 0.2);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    /* display: ${(props) => (props.success ? "block" : "none")}; */
`;
const ModalContent = styled.div`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    text-align: center;
    max-width: 700px;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Title = styled.h2`
    font-size: 24px;
    color: ${COLORS.PRIMARY_TEXT};
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    margin-top: 30px;
`;
const ImgContainer = styled.div`
    width: 80px;
    height: 80px;
    img {
        width: 100%;
        height: 100%;
    }
`;
const Button = styled.div`
    background: linear-gradient(to top, #ffdf17 0%, #fff79e 100%);
    width: 200px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 500;
`;
const Paragraph = styled.p`
    font-size: 17px;
    color: rgb(116, 116, 116);
    width: 382px;
    margin: auto;
    margin-bottom: 20px;
`;

import React, { useState, useEffect, useMemo } from "react";
import useGetApi from "../../hooks/useGetApi";
import Table from "../general/Table";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import { Avatar, Button } from "@mui/material";
import CancelledIcon from "../../assets/images/cancel.svg";

import TickSquare from "../../assets/images/tick-square.svg";
import "../../assets/css/VendorProduct.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TimeIcon from "../../assets/images/timeCont.svg";

const VendorProduct = () => {
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const dispatch = useDispatch();
	const [alert, setAlert] = useState({
		visible: false,
		message: "",
		type: "",
	});
	const filterData = [
		{ id: 1, name: "All" },
		{ id: 2, name: "Pending" },
		{ id: 3, name: "Active" },
		{ id: 4, name: "Inactive" },
		{ id: 5, name: "Rejected" },
	];
	const [activeFilter, setActiveFilter] = useState({ id: 1, name: "All" });
	useEffect(() => {
		dispatch(updateActiveMenu("Vendors Products"));
	}, [dispatch]);
	const { data, refetch, loading } = useGetApi(
		"shops/products/vendor-products/",
		{
			page,
		}
	);

	const [vendorDataProducts, setVendorDataProducts] = useState(data);

	useEffect(() => {
		if (data) {
			setVendorDataProducts(data);
		}
	}, [data]);
	useEffect(() => {
		if (vendorDataProducts?.count) {
			setPageCount(Math.ceil(vendorDataProducts.count / 10)); // Assuming page size is 10
		}
	}, [vendorDataProducts]);
	const filteredData = useMemo(() => {
		if (activeFilter.name === "All") {
			return vendorDataProducts?.results || [];
		}
		if (activeFilter.name === "Inactive") {
			return (vendorDataProducts?.results || []).filter(
				(vendor) => vendor.status === "inactive" || vendor.status === "pending"
			);
		}
		return (vendorDataProducts?.results || []).filter(
			(vendor) => vendor.status === activeFilter.name.toLowerCase()
		);
	}, [activeFilter, vendorDataProducts]);
	function stringAvatar(name) {
		return {
			sx: {
				bgcolor: "#FFF199",
			},
			children: `${name
				?.split(" ")
				.map((n) => n[0])
				.slice(0, 2)
				.join("")}`,
		};
	}
	const [popoverData, setPopoverData] = useState({
		isVisible: false,
		position: { top: 0, left: 20 },
	});

	const columns = useMemo(
		() => [
			{
				Header: "Sl No",
				accessor: "id",
				Cell: ({ cell }) => (
					<div
						style={{
							width: "50px",
						}}
					>
						{cell.row.index > 9 ? cell.row.index + 1 : `0${cell.row.index + 1}`}
					</div>
				),
			},
			{
				Header: "Product ID",
				accessor: "auto_id",
				Cell: ({ cell }) => <span>{"#" + cell.row.original.product_code}</span>,
			},
			{
				Header: "Product Detail",
				accessor: "name",
				Cell: ({ cell }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<div>
							<h4
								style={{
									fontSize: "18px",
									color: "#000",
									fontWeight: 500,
								}}
							>
								{cell.row.original.name}
							</h4>
							<h5
								style={{
									fontSize: "16px",
									color: "#808080",
									fontWeight: 400,
								}}
							>
								{cell.row.original.description}
							</h5>
						</div>
					</div>
				),
			},
			{
				Header: "Vendor Detail",
				accessor: "vendor_name",
				Cell: ({ cell }) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<Avatar {...stringAvatar(cell.row.original.vendor_name)} />
						<div>
							<h4
								style={{
									fontSize: "18px",
									color: "#000",
									fontWeight: 500,
								}}
							>
								{cell.row.original.vendor_name}
							</h4>
							<h5
								style={{
									fontSize: "16px",
									color: "#808080",
									fontWeight: 400,
								}}
							>
								{cell.row.original.vendor_phone}
							</h5>
						</div>
					</div>
				),
			},
			{
				Header: "Date",
				accessor: "date",
				Cell: ({ cell }) => <span>{cell.value}</span>,
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: ({ cell }) => {
					const status = cell.row.original.verification_status;

					/*
					The admin verification statuses are as follows:
						pending
						reject
						approved
					*/
					if (status == "pending") {
						return (
							<div
								style={{
									display: "flex",
									gap: "10px",
									background: "#FFFFF0",
									padding: "10px 15px",
									borderRadius: "5px",
									color: "#FFB240",
								}}
							>
								<img src={TimeIcon} alt="" />
								Pending
							</div>
						);
					} else if (status == "reject") {
						return (
							<div
								style={{
									display: "flex",
									gap: "10px",
									background: "#FCEAE8",
									padding: "10px 15px",
									borderRadius: "5px",
									color: "#E02B1D",
								}}
							>
								<img src={CancelledIcon} alt="" />
								Rejected
							</div>
						);
					} else if (status == "approved") {
						return (
							<div
								style={{
									display: "flex",
									gap: "10px",
									background: "#EAF8F2",
									padding: "10px 15px",
									borderRadius: "5px",
									color: "#009262",
								}}
							>
								<img src={TickSquare} alt="" />
								Verified
							</div>
						);
					}
				},
			},
			{
				Header: "Action",
				accessor: "actions",
				Cell: () => (
					<>
						<Button
							onClick={(e) => {
								e.stopPropagation();
								handlePopoverOpen(e);
							}}
						>
							<MoreHorizIcon />
						</Button>
						{popoverData.isVisible && (
							<div
								className="custom-popover"
								style={{
									top: `${popoverData.position.top}px`,
									left: `${popoverData.position.left}px`,
								}}
								onMouseLeave={handlePopoverClose}
							>
								<div
									className="popover-item popover-item-green"
									onClick={() => console.log("Product Verified")}
								>
									✅ Product Verification
								</div>
								<div
									className="popover-item popover-item-red"
									onClick={() => console.log("Product Rejected")}
								>
									❌ Product Rejection
								</div>
							</div>
						)}
					</>
				),
			},
		],
		[popoverData]
	);

	const handlePopoverOpen = (event) => {
		const rect = event.currentTarget.getBoundingClientRect();
		setPopoverData({
			isVisible: true,
			position: {
				top: rect.bottom + window.scrollY,
				left: rect.left + window.scrollX,
			},
		});
	};

	const handlePopoverClose = () => {
		setPopoverData({ isVisible: false, position: { top: 0, left: 0 } });
	};

	return (
		<div>
			<TopContainer>
				{filterData.map((item) => (
					<Button
						key={item.id}
						onClick={() => setActiveFilter(item)}
						sx={{
							backgroundColor: activeFilter.id === item.id ? "#FFF2A1" : null,
							color: activeFilter.id === item.id ? "#000" : "#747474",
						}}
						style={{ textTransform: "capitalize" }}
					>
						{item.name}
					</Button>
				))}
			</TopContainer>
			<Table
				columns={columns}
				data={filteredData}
				fetchData={refetch}
				loading={loading}
				pageCount={pageCount}
				totalPages={pageCount}
				setPage={setPage}
				currentPage={page}
				vendorData={vendorDataProducts}
				setVendorDataProducts={setVendorDataProducts}
				alert={alert}
			/>
		</div>
	);
};

export default VendorProduct;

const TopContainer = styled.div`
	width: 100%;
	padding: 20px 20px;
	background: #fff;
	margin-bottom: 10px;
	border-radius: 8px;
	gap: 20px;
	display: flex;
	flex-wrap: wrap;
`;

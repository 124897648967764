import React, { useState } from "react";
import styled from "styled-components";
import ProductActionButton from "./ProductActionButton";
import axiosInstance from "../../../api";

const ProductTable = ({ products, setProducts }) => {
    const [selectedProduct, setSelectedProduct] = useState("");

    const [toggledStates, setToggledStates] = useState({});

    const handleToggle = async (id, newStatus) => {
        setToggledStates((prevState) => ({
            ...prevState,
            [id]: newStatus,
        }));

        setProducts((prevList) =>
            prevList.map((product) =>
                product.id === id
                    ? {
                          ...product,
                          item_status: newStatus ? "active" : "inactive", // Update the status immediately
                      }
                    : product
            )
        );

        try {
            const response = await axiosInstance.post(
                `/shops/products/${id}/update-status/`,
                {
                    status: newStatus ? "active" : "inactive",
                }
            );

            if (response.data.StatusCode === 6000) {
            } else {
                throw new Error("Failed to update product status");
            }
        } catch (error) {
            setToggledStates((prevState) => ({
                ...prevState,
                [id]: !newStatus,
            }));

            setProducts((prevList) =>
                prevList.map((product) =>
                    product.id === id
                        ? {
                              ...product,
                              item_status: !newStatus ? "active" : "inactive", // Revert status to previous state
                          }
                        : product
                )
            );
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
    };
    return (
        <Table>
            <thead>
                <TableRow>
                    <TableHeader>Sl No.</TableHeader>
                    <TableHeader>Image</TableHeader>
                    <TableHeader>Product Name</TableHeader>
                    <TableHeader>Similar Products</TableHeader>
                    <TableHeader>Created Date</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader>Action</TableHeader>
                </TableRow>
            </thead>
            <tbody>
                {products.map((product, index) => {
                    const isChecked =
                        toggledStates[product.id] ??
                        product.item_status === "active";

                    const handleProductToggle = () => {
                        const updatedStatus = !isChecked;
                        handleToggle(product.id, updatedStatus); // Call handleToggle with product id
                    };

                    return (
                        <TableRow key={product.id}>
                            <TableData>{index + 1}</TableData>
                            <TableData>
                                <ProductImage
                                    src={product.featured_image}
                                    alt={product.name}
                                />
                            </TableData>
                            <TableData>{product.name}</TableData>
                            <TableData>
                                {product.similar_products_count}
                            </TableData>
                            <TableData>
                                {formatDate(product.date_added)}
                            </TableData>
                            <TableData>{product.item_status}</TableData>
                            <TableData className="toggle">
                                <ProductActionButton
                                    data={product}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                    isChecked={isChecked}
                                    disabled={false} // Assuming it's not disabled in this context
                                    toggled={isChecked}
                                    setToggled={handleProductToggle} // Handle toggle here
                                    handleToggle={handleProductToggle} // This will trigger toggle
                                />
                            </TableData>
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default ProductTable;

// Styled Components
const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    min-width: 1100px;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
    color: #444;
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    text-align: left;
    :last-child {
        text-align: end;
    }
`;

const TableData = styled.td`
    color: #444;
    font-size: 14px;
    padding: 12px;
    text-align: left;
`;

const ProductImage = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 5px;
    object-fit: cover;
`;

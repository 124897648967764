// Table.js
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import styled from "styled-components";
import tick from "../../assets/images/Tick_Yellow.svg";

const Styles = styled.div`
    padding: 1rem;
    background: #fff;
    border-radius: 8px;
    table {
        width: 100%;
        border-spacing: 0;
        text-align: left;
        /* border: 1px solid black; */
        th {
            border-bottom: 1px solid #e8e8e8;
            &:last-child {
                text-align: left;
            }
        }
        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #e8e8e8;
            &:last-child {
                text-align: left;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
    }
`;

const Table = ({
    columns,
    data = [],
    fetchData,
    loading,
    totalPages,
    setPage,
    currentPage = 1,
    pageCount,
    vendorData,
    alert = { visible: false, message: "", type: "" },
}) => {
    const totalEntries = vendorData?.count;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: pageCount,
        },
        usePagination
    );

    useEffect(() => {}, [fetchData, pageIndex, pageSize]);

    return (
        <Styles>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <>
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    </>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {alert.visible && (
                <div
                    className={`alert ${alert.type}`}
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        padding: "15px 20px",
                        backgroundColor:
                            alert.type === "success" ? "#0A0A0A" : "red",
                        color: "white",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={tick}
                        alt="image"
                        width={20}
                        style={{ marginRight: "5px" }}
                    />
                    <p>{alert.message}</p>
                </div>
            )}
            <div
                className="pagination"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px 0px",
                }}
            >
                {loading ? (
                    <span colSpan="10000">Loading...</span>
                ) : (
                    <span colSpan="10000">
                        Showing {page.length} of {""}
                        {totalEntries} entries
                    </span>
                )}
                <div style={{ display: "flex", gap: "5px" }}>
                    <Button>{"<"}</Button>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Button
                            key={i}
                            sx={{
                                backgroundColor:
                                    currentPage == i + 1
                                        ? "#FFDF17"
                                        : "#F5F7FB",
                                color: "#000",
                            }}
                            onClick={() => {
                                setPage(1 + i);
                                fetchData();
                            }}
                        >
                            {i + 1}
                        </Button>
                    ))}
                    <Button>{">"}</Button>
                </div>
            </div>
        </Styles>
    );
};

export default Table;

import React, { useState } from "react";
import styled from "styled-components";

const ToggleSwitch = ({ toggled, setToggled, handleToggle, disabled }) => {
    return (
        <ToggleContainer>
            <button
                className={`toggle_btn ${toggled ? "toggled" : ""}`}
                onClick={handleToggle}
                disabled={disabled}
            >
                <div className="thumb"></div>
            </button>
        </ToggleContainer>
    );
};

export default ToggleSwitch;

// Styled components for toggle
const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & button.toggle_btn {
        background-color: #d1d9e2;
        border: none;
        border-radius: 99px;
        width: 50px;
        height: 28px;
        transition: background-color 0.3s ease, transform 0.3s ease;
        cursor: pointer;
        position: relative;
    }

    & button.toggled {
        background: linear-gradient(to right, #fef7a3 0%, #feda00 100%);
    }

    & button:hover {
        border-color: #feda00;
    }

    & div.thumb {
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 99px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.3s ease;
        left: 3px;
    }

    & button.toggled div.thumb {
        transform: translateY(-50%) translateX(22px); /* Move thumb to the right when toggled */
    }
`;

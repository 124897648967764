import styled from "styled-components";

const CheckboxWrapper = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	appearance: none;
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
`;

const StyledCheckbox = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border: 2px solid ${(props) => props.themeColor || "#D4A017"};
	border-radius: 7px;
	background-color: white;
	transition: all 0.2s ease-in-out;
	position: relative;

	${HiddenCheckbox}:checked + &::after {
		content: "✔";
		font-size: 16px;
		color: ${(props) => props.themeColor || "#D4A017"};
		position: absolute;
	}
`;

const CustomCheckbox = ({ checked, onChange, themeColor }) => {
	return (
		<CheckboxWrapper>
			<HiddenCheckbox checked={checked} onChange={onChange} />
			<StyledCheckbox themeColor={themeColor} />
		</CheckboxWrapper>
	);
};

export default CustomCheckbox;

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { COLORS } from "../../../constants";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axiosInstance from "../../../api";
import { ToastContainer, toast } from "react-toastify";

function BankDetails({ shop_data }) {
    const [activeInputs, setActiveInputs] = useState({
        account_holder_name: false,
        bank_account_number: false,
        bank_ifsc_code: false,
        bank_name: false,
        bank_branch: false,
    });
    const schema = Yup.object({
        account_holder_name: Yup.string().required(
            "Account Holder Name is Required"
        ),
        // .matches(
        // 	/^[abcdefghijklmnopqrstuvwxyz]+$/,
        // 	"Only words are allowed"
        // ),
        bank_ifsc_code: Yup.string().required("IFSC Code is Required"),
        bank_name: Yup.string().required("Bank Name is Required"),
        bank_branch: Yup.string().required("Bank Name is Required"),
        // .matches(
        // 	/^[abcdefghijklmnopqrstuvwxyz]+$/,
        // 	"Only words are allowed"
        // ),
        bank_account_number: Yup.number()
            .positive("Account Number must be a positive number")
            .required("Account Number is Required")
            .min(10, "Account Number must be a 16 digit number"),
    });
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const handleFocus = (field) => {
        setActiveInputs({
            account_holder_name: false,
            bank_account_number: false,
            bank_ifsc_code: false,
            bank_name: false,
            bank_branch: false,
            [field]: true,
        });
    };

    const handleBlur = (field) => {
        setActiveInputs((prev) => ({
            ...prev,
            [field]: false,
        }));
    };
    useEffect(() => {
        if (shop_data) {
            setValue("account_holder_name", shop_data.account_holder_name);
            setValue("bank_account_number", shop_data.bank_account_number);
            setValue("bank_ifsc_code", shop_data.bank_ifsc_code);
            setValue("bank_name", shop_data.bank_name);
            setValue("bank_branch", shop_data.bank_branch);
        }
    }, [shop_data]);
    const onSubmit = (data) => {
        axiosInstance
            .patch(`shops/${shop_data.id}/`, data)
            .then((res) => {
                const response_data = res.data;
                reset({
                    account_holder_name: response_data.account_holder_name,
                    bank_account_number: response_data.bank_account_number,
                    bank_ifsc_code: response_data.bank_ifsc_code,
                    bank_name: response_data.bank_name,
                    bank_branch: response_data.bank_branch,
                });
                alert("Shop details updated successfully!");
            })
            .catch((err) => {
                alert("Failed to update shop!");
            });
    };
    return (
        <Container>
            <Title>Shop Details</Title>
            <FormSection onSubmit={handleSubmit(onSubmit)}>
                <InputWrapper>
                    <InputLabel>Account Holder Name</InputLabel>
                    <TextInput
                        className={
                            activeInputs.account_holder_name ? "active" : ""
                        }
                        onFocus={() => handleFocus("account_holder_name")}
                        onBlur={() => handleBlur("account_holder_name")}
                        {...register("account_holder_name")}
                        type="text"
                        name="account_holder_name"
                        placeholder="Enter account holder name"
                    />
                    <Error>{errors.account_holder_name?.message}</Error>
                </InputWrapper>
                <InputWrapper>
                    <InputLabel>Bank Account Number</InputLabel>
                    <TextInput
                        className={
                            activeInputs.bank_account_number ? "active" : ""
                        }
                        onFocus={() => handleFocus("bank_account_number")}
                        onBlur={() => handleBlur("bank_account_number")}
                        {...register("bank_account_number")}
                        type="numeric"
                        name="bank_account_number"
                        placeholder="0000 0000 0000 0000"
                    />
                    <Error>{errors.bank_account_number?.message}</Error>
                </InputWrapper>
                <InputWrapper>
                    <InputLabel>Bank IFSC Number</InputLabel>
                    <TextInput
                        className={activeInputs.bank_ifsc_code ? "active" : ""}
                        onFocus={() => handleFocus("bank_ifsc_code")}
                        onBlur={() => handleBlur("bank_ifsc_code")}
                        {...register("bank_ifsc_code")}
                        type="text"
                        name="bank_ifsc_code"
                        placeholder="Enter IFSC"
                    />
                    <Error>{errors.bank_ifsc_code?.message}</Error>
                </InputWrapper>
                <InputWrapper>
                    <InputLabel>Name of Bank</InputLabel>
                    <TextInput
                        className={activeInputs.bank_name ? "active" : ""}
                        onFocus={() => handleFocus("bank_name")}
                        onBlur={() => handleBlur("bank_name")}
                        {...register("bank_name")}
                        type="text"
                        name="bank_name"
                        placeholder=""
                    />
                    <Error>{errors.bank_name?.message}</Error>
                </InputWrapper>
                <InputWrapper>
                    <InputLabel>Bank Branch</InputLabel>
                    <TextInput
                        className={activeInputs.bank_branch ? "active" : ""}
                        onFocus={() => handleFocus("bank_branch")}
                        onBlur={() => handleBlur("bank_branch")}
                        {...register("bank_branch")}
                        type="text"
                        name="bank_branch"
                        placeholder=""
                    />
                    <Error>{errors.bank_name?.message}</Error>
                </InputWrapper>
                <ButtonContainer>
                    <CancelButton>Cancel</CancelButton>
                    <SubmitButton type="submit">Save</SubmitButton>
                </ButtonContainer>
            </FormSection>
        </Container>
    );
}

export default BankDetails;
const Container = styled.div`
    background-color: #fff;
    padding: 20px;
    position: relative;
`;
const Title = styled.h3`
    font-size: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${COLORS.SECONDARY};
    font-family: dm_sans_reg;
`;
const FormSection = styled.form`
    display: block;
    margin-top: 30px;
`;
const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
`;

const InputLabel = styled.label`
    font-size: 16px;

    color: ${COLORS.SECONDARY_TEXT};
`;
const TextInput = styled.input`
    padding: 12px 10px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 60%;
    border: 1px solid #eeeeee;
    &.active {
        border: ${COLORS.THEME_COLOR} solid 1px;
    }
`;
const Error = styled.p`
    font-size: 12px;
    color: red;
    bottom: -20px;
    right: 0;
    position: absolute;
`;
const SectionBreaker = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
`;
const SectionTitle = styled.p`
    color: ${COLORS.PRIMARY_TEXT};
    font-size: 16px;
    margin-right: 20px;
`;
const Line = styled.span`
    width: 100%;
    display: flex;
    align-items: center;
    span {
        display: block;
        flex: 1;
        border-bottom: 1px solid #f0f0f0;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;
const CancelButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    color: ${COLORS.SECONDARY_TEXT};
`;
const SubmitButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    color: ${COLORS.PRIMARY_TEXT};
    background: ${COLORS.THEME_GRADIENT};
    border: none;
    border-radius: 5px;
`;

import React, { useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, COLORS } from "../../../constants";
import Pagination from "../../general/Pagination";
import { stringFormatDate } from "../../utils/HelperFunctions";
import OrderActionsButton from "../Orders/OrderActionsButton";
import AddStockModal from "./modals/AddStockModal";
import axiosInstance from "../../../api";
import tick from "../../../assets/images/sucess.svg";

function InventoryTable({
	data,
	fetchData,
	loading,
	pageCount,
	totalPages,
	page,
	setPage,
	currentPage,
	inventaryData,
}) {
	const [selectedOrder, setSelectedOrder] = useState({});
	const [isConfirmModal, setConfirmModal] = useState(false);
	const [isCancelModal, setCancelModal] = useState(false);
	const [addStockModal, setAddStockModal] = useState(false);

	const handleConfirmModal = () => {
		setConfirmModal(!isConfirmModal);
	};
	const handleCancelModal = () => {
		setCancelModal(!isCancelModal);
	};
	const handleAddStockModal = () => {
		setAddStockModal(!addStockModal);
	};
	const handleConfirmClick = () => {
		setConfirmModal(false);
		toast(<ToastSucessMessage icon={tick} />);
	};
	const [stockData, setStockData] = useState({});

	const getStockCount = async (product_id) => {
		try {
			const res = await axiosInstance.get(
				`${API_BASE_URL}shops/admin/inventory/get-product-stocks/${product_id}/`
			);
			setStockData(res.data); // Update state with the response data
		} catch (err) {
			console.error("Failed to get stock status:", err);
		}
	};

	return (
		<Container>
			<TableContainer>
				<thead>
					<TableRow>
						<TableHead className="slno">SI No.</TableHead>
						<TableHead>Product Code</TableHead>
						<TableHead>Image</TableHead>
						<TableHead>Product Name</TableHead>
						<TableHead>Stock</TableHead>

						<TableHead style={{ textAlign: "right" }}>Actions</TableHead>
					</TableRow>
				</thead>
				<TableBody>
					{data?.map((data, key) => (
						<TableRow key={key}>
							<TableCell className="slno">
								{data.auto_id < 10 ? "0" + data.auto_id : data.auto_id}
							</TableCell>
							<TableCell>{data.product_code}</TableCell>
							<TableCell>
								<Image src={data.featured_image} alt="" />
							</TableCell>
							<TableCell>{data.name}</TableCell>
							<TableCell>{data.stock_status.total_stock}</TableCell>
							<TableCell>
								<OrderActionsButton
									handleCancelModal={handleCancelModal}
									handleConfirmModal={handleConfirmModal}
									data={data}
									selectedOrder={selectedOrder}
									setSelectedOrder={setSelectedOrder}
									handleAddStockModal={handleAddStockModal}
									getStockCount={getStockCount}
								/>
							</TableCell>
							<AddStockModal
								handleModal={handleAddStockModal}
								isModal={addStockModal}
								stockData={stockData}
								fetchData={fetchData}
							/>
						</TableRow>
					))}
				</TableBody>
			</TableContainer>
			<Pagination
				page={page}
				pageCount={pageCount}
				setPage={setPage}
				orderData={inventaryData}
			/>
		</Container>
	);
}

export default InventoryTable;
const Container = styled.div`
	overflow-x: scroll;
	background-color: ${COLORS.PRIMARY};
	padding: 10px;

	height: calc(100vh - 210px);
	width: 100%;
	padding-bottom: 60px;
`;
const TableContainer = styled.table`
	border-collapse: collapse;
	width: 100%;
	min-width: 1150px;
`;
const TableBody = styled.tbody`
	transition: 0.3s;
	tr {
		transition: 0.3s;
		&:hover {
			transition: 0.3s;
			background-color: ${COLORS.SECONDARY};
		}
	}
`;

const TableHead = styled.th`
	font-weight: bold;
	text-align: left;
	padding: 10px 15px;
`;

const TableRow = styled.tr`
	border-bottom: 1px solid ${COLORS.SECONDARY};
	.slno {
		/* max-width: 100px;
		display: flex;
		align-items: center;
		height: 100%; */
	}
`;

const TableCell = styled.td`
	text-align: left;
	vertical-align: middle;
	padding: 15px;
	font-size: 14px;
	max-width: 150px;
`;

const TableButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	overflow: visible;
	&:hover {
	}
`;

const Image = styled.img`
	width: 80px;
	height: 60px;
	object-fit: cover;
`;
